import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { deleteStaff, saveStaff, getStaff, searchStaff } from './../../services/staffService';
import ConfirmDelete from "../common/confirmDelete";
import FontAwesome from 'react-fontawesome';
import { toast } from "react-toastify";
import SearchBox from "../common/searchBox";
import { getAreas } from "../../services/areaService";
import SearchStaffTable from "./searchStaffTable";

class StaffForm extends Form {
  _isMounted = false;

  state = {
    _id: "",
    isNew: true,
    currentPage: 1,
    searchStaff: "",
    searchList: [],
    listAreas: [],
    isModalDeleteOpen: false,
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    empId: Joi.number().required().label("Employee ID"),
    name: Joi.string().required().label("Name - Surname"),
    sim: Joi.number().max(999999999).required().label("Sim Number"),
    branch: Joi.array().min(1).max(1),
    area: Joi.array().min(1).max(1)
  };

  getAllData() {
    return {...this.state.data}
  }

  getAllSchema() {
    return {...this.schema}
  }

  async componentDidMount() {
    this._isMounted = true;

    // await this.loadAreas();

    const id = this.props.match.params.id;
    if ( id !== 'new') {
       await this.loadStaff(id);
    }
  }

  componentWillUnmount() { this._isMounted = false;  }


  async loadStaff(id) {
    try {

      const { data } = await getStaff(id);

      if ( data.status && data.status==='success') {

        const { staff } = data.data;
        console.log(staff);
        const editStaff = [staff];

        if( this._isMounted ) this.setState({ searchList: editStaff, isNew: false, _id: staff._id });
        
      } else {
          toast.error("Staff is not found");
          this.props.history.replace("/staff");
      }
      
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  handleSingleSelectExtend = (name, item, data, errors) => {
    const { area } = data;

    if ( name==='branch' && (area && area.length>0)) {
      const getBranch = this.state.listBranches.find(x=>x.value===item._id);
      const selectArea = area.find(x=>x.value).value;

      if ( !(getBranch && getBranch.areas && getBranch.areas.filter(x=>x._id===selectArea).length>0))  data.area = [];

    }

    this.setState({ data, errors })
  }

  handleSearch = async query => {
    this.setState({ searchStaff: query, currentPage: 1 });

    if ( query.length> 2) {
      try {
        const { data: result } = await searchStaff(query, 0);
        if ( result.status==='success') {
  
            if(this._isMounted) this.setState({ searchList: result.data.searchList });
  
        } else {
          toast.error(result.message);
        }
  
      } catch (err) {
        console.log(err);
      }
    }

  };

  async loadAreas() {
    const { data } = await getAreas();

    if ( data.status === 'success') {
      if ( this._isMounted) this.setState({ listAreas : data.data.areas });
    }
  }

  handleClickAdd = async item => {
    // console.log("click add", item)
    try {
    
      const { data: result } = await saveStaff(item);
      // console.log("Result", result);
      if ( result.status === 'success') {
        this.props.history.replace("/staff");
      } else {
        toast.error("Error : " + result.message);
      }
    } catch(err) {
      toast.error("Error : " + err.message);
    }
  }

  doSubmit = async () => {

    try {
      const { data: result } = await saveStaff(this.state.data);
      // console.log("Result", result);
      if ( result.status === 'success') {
        this.props.history.replace("/staff");
      } else {
        toast.error("Error : " + result.message);
      }
    } catch(err) {
      toast.error("Error : " + err.message);
    }
    
  };

  doDelete = async () => {
    this.setState({isModalDeleteOpen: true})
  }

  onDeleteCancel = () => {
    this.setState({isModalDeleteOpen: false})
  }

  onDeleteConfirm = async () => {
    this.setState({isModalDeleteOpen: false})

    const { _id } = this.state;

    if ( _id ) {
      try {
        await deleteStaff(_id)
        this.props.history.replace("/staff");
      } catch(err) {

      }
      
    }
  }

  render() {
    const headerName = (this.props.match.params.id==='new') ? 'เพิ่มทีมงาน' : 'Edit Staff';

    const { isModalDeleteOpen, searchStaff, searchList, isNew } = this.state;


    return (
      <React.Fragment>
        <ConfirmDelete isOpen={isModalDeleteOpen} onConfirm={this.onDeleteConfirm} onCancel={this.onDeleteCancel} />
        <div className="row mb-4">
          <div className="col-auto mr-auto">
          <h4>{headerName}</h4>
          </div>
          <div className="col-auto d-lg-none">
            <FontAwesome  name="times-circle" size="2x" onClick={() => this.props.history.replace("/staff")} />
          </div>
        </div>
        <form action="" onSubmit={this.handleSubmit}>
          {isNew && <div className="row mb-4">
            <div className="col-auto align-self-center">ค้นหาพนักงาน</div>
            <div className="col-12 col-md-6">
              <SearchBox value={searchStaff} onChange={this.handleSearch} />
            </div>
          </div>}
          <div>
            {searchList && searchList.length>0 && 
            <SearchStaffTable data={searchList} handleClickAdd={this.handleClickAdd} isNew={isNew} /> }
          </div>
          
          {/* {empId && listAreas && this.renderSingleSelectButton("area", "พื้นที่ปฏิบัติงาน", listAreas)} */}
          <div className="row col mt-5 justify-content-center">
            {/* {empId && this.renderButton("Save")} */}
            {!isNew && <div className="ml-4">{this.renderRedButton("Delete", this.doDelete)}</div>}
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default StaffForm;
