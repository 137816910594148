import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import moment from 'moment';
import socketIOClient from '../../services/socketioService';
import { toast } from 'react-toastify';
import { getWork, getAudience } from '../../services/workService';
import EventCardAudience from './eventCardAudience';
import EventCardComment from './eventCardComment';
import EventCardReview from './eventCardReview';
import EventCardZoom from './eventCardZoom';

import jsfiledownload from 'js-file-download'
import { downloadWorkReport } from './../../services/reportService'

class EventCard extends Component {
    _isMounted = false;
    workTimeInterval = null;

    state = { 
        work: {} 
    }

    componentDidMount() {
        this._isMounted = true;

        const { id} = this.props;
        this.loadEvent(id);
        this.loadAudience(id);

        // const data = this.props.item;
        // const {  start, finish } = data;
        // data.workTime = (finish) ? this.getWorkHour(start, finish) : this.getWorkHour(start, Date.now());

        // this.setState({ data });
        this.setupSocketIO();

        // if ( data.status==='active' && data.type!=='emergency') {
        //     this.workTimeInterval = setInterval( this.updateWorkTime, 60000);
        // }
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.workTimeInterval);
        socketIOClient.off('update-work-content');
        socketIOClient.off('activity-item-audience-list');
      }
    
      setupSocketIO = () => {
        socketIOClient.on('update-work-content', message => {
            console.log("socketio", "update-work-content", message);
            if ( this.props.id === message) {
                this.loadEvent(message)
            }
          })

        socketIOClient.on('activity-item-audience-list', message => {
            console.log('socketio', 'activity-item-audience-list', message)
            if( this.props.id === message ) {
                this.loadAudience(message);
            }
        })
      }

      updateWorkTime = () => {
          const data = {...this.state.data};
          
        data.workTime = this.getWorkHour(data.start, Date.now());
        this.setState({data});
        // console.log('update work time');
      }

      async loadEvent(id) {
        try {
            const { data } = await getWork(id);
                if ( data.status && data.status==='success') {
                    const { work } = data.data;

                    if ( this._isMounted) {
                        this.setState({ work })
                    }
    
                } else {
                    toast.error(data.message);
                }
          } catch (ex) {
            if (ex.response && ex.response.status === 404)
              this.props.history.replace("/not-found");
          }
    }

    async loadAudience(id) {
        try {
            const { data } = await getAudience(id);
                if ( data.status && data.status==='success') {
                    const { audience, reviewSummary } = data.data;

                    if ( this._isMounted) {
                        this.setState({ audience, reviewSummary })
                    }
    
                } else {
                    toast.error(data.message);
                }
          } catch (ex) {
            if (ex.response && ex.response.status === 404)
              this.props.history.replace("/not-found");
          }
    }

    getWorkHour(start, end) {

        const diff = moment(end).diff(start, 'minutes');
        
        const days = Math.floor(diff/60/24);
        const day_text = (days>0) ? (days + ((days>1) ? ' วัน': ' วัน')) : '';
        
        const hours = Math.floor((diff%1440)/60);
        const hour_text = (hours>0) ? (hours + ((hours>1) ? ' ชั่วโมง': ' ชั่วโมง')) : '';
        
       const mins = Math.floor(diff%60);
       const min_text = (mins>0) ? (mins + ((mins>1) ? ' นาที': ' นาที')) : '';
 
        return (((day_text) ? day_text + ' ' : '') + ((hour_text) ? hour_text + ' ' : '') + ((min_text) ? min_text : '')).trimRight();
    }

    handleReportDownload = async () => {
        // console.log("start download")
        try {
            const { id} = this.props;
            if(!id) return;


            const output = await downloadWorkReport(id);
            const {data: result, headers} = output

            if( result.status==='error') {
                toast.error(result.message)
            } else {
                if ( headers['x-suggested-filename']==='nodata') {
                    toast.error("No Report")
                } else {
                    jsfiledownload(result, headers['x-suggested-filename'] || `report.xlsx`)
                }
            }
            

        } catch(err) {
            toast.error("ผิดพลาด " + err.message)
        }
    }

    render() { 
        const { _id, name, message, type, start_text, update_text, finish_text, options, photo, zoom, autoClose } = this.state.work;
        const { area, owner, status, start, update, finish } = this.state.work;
        const { audience, reviewSummary } = this.state;
        const workTime = this.getWorkHour(start, finish || update);


        if (!_id) return (<div></div>);

        const classHeader = (type==='info') ? 'card-header bg-info text-white py-2'
                : (status==='active') ? 'card-header bg-warning py-2' 
                : (status==='finish') ? 'card-header bg-success text-white py-2'
                : 'card-header bg-secondary py-2';


        return ( 
            <div className="container mb-4">
                <div className="card">
                    <div className={classHeader}>
                        <div className="row align-items-center justify-content-between">
                            <div className="col-auto">
                                <div className="row align-items-center">
                                    {area && <div className="col-auto px-2"><h5 className="d-inline"><span className="badge badge-light">{area}</span></h5></div>}
                                    {owner && <div className="col-auto px-2"><h5 className="d-inline"><FontAwesome name="user" /> {owner}</h5></div>}
                                </div>
                            </div>
                            <div className="col-auto">
                                <div><FontAwesome name="clock-o" /> {workTime}</div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row justify-content-between">
                            <div className="col-auto"><h5 className="card-title">{name}</h5></div>
                            <div className="col-auto">
                                <button type="button" onClick={this.handleReportDownload} className="btn btn-primary">Download <FontAwesome name="download" /></button>
                            </div>
                        </div>
                        {message && message.map(item => <p key={item._id} className="card-text">{item.text}</p>)}
                    </div>
                    <div className="card-body">
                        <span className="card-text">
                            {options && options.map(item => {
                                if (item.tag==='line') {
                                    const lines = item.value.split(',');
                                    return (<div key={item.tag}><h5>{item.name} 
                                    {
                                        lines.map(line => {
                                            return (<span key={line} className="badge badge-info ml-2">{line}</span>)
                                        })
                                    }
                                    </h5></div>)
                                } else {
                                    return (<div key={item.tag}><h5>{item.name} <span className="badge badge-info">{item.value}</span></h5></div>)
                                }
                            })}
                        </span>
                        {photo && <div className="card-text mt-4"><div className="row">
                            {photo.map(item => <div key={item._id} className="col-12 col-lg-6"><img style={{width: "100%"}} src={item.url} alt={item._id} /></div>)}
                        </div></div>}
                        {zoom && <EventCardZoom data={zoom} />}
                        {(status==='finish') && <EventCardReview data={reviewSummary} />}
                        <EventCardAudience data={audience} />
                        {(status==='finish') && <EventCardComment data={audience} />}
                    </div>
                    <div className="card-footer text-right small py-2">
                        <FontAwesome name="clock-o" />เริ่มต้น {start_text}<br />
                        {(!finish && start!==update) && <div><FontAwesome name="clock-o" /> ล่าสุด {update_text}<br /></div>}
                        {(finish && finish_text) && <div><FontAwesome name="clock-o" /> เสร็จสิ้น {finish_text} {autoClose && <span className="badge badge-warning">AUTO-CLOSE</span>}<br /></div>}
                        
                    </div>
                </div>
            </div>
         );
    }
}
 
export default EventCard;