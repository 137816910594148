import React from "react";

const ButtonGroup = props => {
  const {
    items,
    textProperty,
    valueProperty,
    onItemSelect,
    selectedItem
  } = props;

  return (
    <ul className="btn-group">
      {items.map(item => {
        return (<button
          key={item[valueProperty]}
          type="button"
          onClick={() => onItemSelect(item)}
          className={
            item === selectedItem ? "btn btn-primary" : "btn btn-outline-dark"
          }
        >
          {item[textProperty]}
        </button>)
      })}
    </ul>
  );
};

ButtonGroup.defaultProps = {
  textProperty: "name",
  valueProperty: "_id"
};

export default ButtonGroup;
