import React, { Component } from 'react';
import moment from 'moment';
import MyCalendar from '../common/calendar';

class PlanDateSelector extends Component {
    _isMounted = false;

  dateComponentFormat = { 
      altInput: true,
    enableTime: true,
    time_24hr: true, defaultHour: 8,
    altFormat: "l j F Y H:i",
    dateFormat: "Y-m-d H:i"
};

  timeComponentFormat = {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    time_24hr: true
  };

    state = { 
        dates: [],
        mode: "",
        disabled: false
     }

     async componentDidMount() {
         this._isMounted = true;

         this.loadProps();
     }

     loadProps() {
       const { dates, disabled } = this.props;
       const mode = (dates && dates.length>0) ? dates[0].mode : 'one';

       const map = dates.map(date => {
        if ( !date.id && date._id) date.id=date._id;
        date['errors'] = {}

        return date;
       })

       if ( this._isMounted) this.setState({ dates: map, mode, disabled })

      //  console.log('mount date', dates);
     }

     componentWillUnmount() { this._isMounted = false; }


     doChangeDateMode = async (newMode) => {

        const { mode } = this.state;
    
        if ( mode===newMode) return;
    
        const dataDate = {};
        dataDate.mode = newMode;
        dataDate.id = Date.now().toString();
        dataDate.errors = {}
    
        if (newMode==='one') {
          
          dataDate.start = moment(new Date()).startOf('hour').toDate();
          dataDate.end = moment(new Date()).startOf('hour').add(2, 'hours').toDate()
    
        } else {
          dataDate.start = moment("08:00", "HH:mm").toDate();
          dataDate.end = moment('10:00', "HH:mm").toDate();
    
        }
    
        const dates = [dataDate]
    
        this.setState({ dates , mode: newMode});

        this.doNotify(dates);
      }

      handleDateChange = ({ currentTarget: input }) => {
        const { id, name, value } = input;
        const { dates} = this.state;
        const updates = dates.map(date => {
          if ( date.id===id) {

            date[name] = (date.mode==='one') ? moment(value, "YYYY-MM-DD HH:mm").toDate() : moment(value, "HH:mm").toDate();
            
            if( name==='start' && date.mode==='one') {
              if ( date['start']>=date['end']) date['end'] = moment(date['start']).add(2, 'hours').toDate();
            } 

            if( name==='end' && date.mode==='one') {
              if ( date['start']>=date['end']) date['start'] = moment(date['end']).add(-2, 'hours').toDate();
            } 

            if (value) delete date.errors[name];
            else date.errors[name] = 'This can not be empty';
          }
          return date;
        })

        if(this._isMounted) this.setState({ dates: updates});
        this.doNotify(updates);
      }

      doNotify = items => {
        const { notifyChange } = this.props;

        if ( typeof notifyChange === 'function') {
            notifyChange({ items: items });
        }
     }

     getWorkHour(start, end) {
       const diff = moment(end).diff(start, 'minutes') + ((start>end) ? 1440 : 0);

       const days = Math.floor(diff/60/24);
       const day_text = (days>0) ? (days + ((days>1) ? ' days': ' day')) : '';
       
       const hours = Math.floor((diff%1440)/60);
       const hour_text = (hours>0) ? (hours + ((hours>1) ? ' hours': ' hour')) : '';
       
      const mins = Math.floor(diff%60);
      const min_text = (mins>0) ? (mins + ((mins>1) ? ' minutes': ' minute')) : '';

       return (((day_text) ? day_text + ' ' : '') + ((hour_text) ? hour_text + ' ' : '') + ((min_text) ? min_text : '')).trimRight();
     }

    render() { 
        const { mode, dates, disabled } = this.state;

        const buttonDateOneClassName = (mode === 'one') ? 'btn btn-info' : 'btn btn-outline-secondary';
        const buttonDateDailyClassName = (mode === 'daily') ? 'btn btn-info' : 'btn btn-outline-secondary';
        const modeText = (mode==='one') ? 'งานครั้งเดียว' : 'งานประจำวัน';

        return ( 
            <div className="card mt-4">
                <div className="card-header">เวลาปฏิบัติงาน</div>
                <div className="container-fluid p-3">
                  {disabled && <h4><span className="badge badge-info p-2">{modeText}</span></h4>}
                  {!disabled && <div className="btn-group mb-3" role="group" >
                    <button type="button" className={buttonDateOneClassName} onClick={() => this.doChangeDateMode('one')} disabled={disabled}>งานครั้งเดียว</button>
                    <button type="button" className={buttonDateDailyClassName} onClick={() => this.doChangeDateMode('daily')} disabled={disabled}>งานประจำวัน</button>
                  </div>}
                  {dates && dates.length>0 &&
                    dates.map( date => {
                      const { mode, errors} = date;
                      let { start, end } = date;

                      const workHour = this.getWorkHour(start, end);

                      start = ( mode==='one' ) ? moment(start).format("YYYY-MM-DD HH:mm") :  moment(start).format("HH:mm");
                      end = ( mode==='one' ) ? moment(end).format("YYYY-MM-DD HH:mm") :  moment(end).format("HH:mm");
                      const calendarClass = (mode==='one') ? 'col-12 col-sm-6 col-md-5 col-lg-4' : 'col-8 col-sm-4 col-md-4 col-lg-3 col-xl-2';
                      const workHourClass = (mode==='one') ? 'col-12 col-sm-6 col-md-5 col-lg-4' : 'col-8 col-sm-4 col-md-4 col-lg-3 col-xl-2';

                      const textPrefix = (mode==='one') ? 'วันที่-เวลา' : 'เวลา';
                      const calendarFormat = (mode==='one') ? this.dateComponentFormat : this.timeComponentFormat;
                      return (
                        <div key={date.id} className="row">
                          <div className={calendarClass}>
                              <MyCalendar 
                                id={date.id}
                                name="start"
                                value={start}
                                label={textPrefix+ " เริ่มต้น"}
                                options={calendarFormat}
                                onChange={this.handleDateChange}
                                error={errors['start']}
                                disabled={disabled}
                              />
                          </div>
                          <div className={calendarClass}>
                            <MyCalendar 
                                id={date.id}
                                name="end"
                                value={end}
                                label={textPrefix+ " สิ้นสุด"}
                                options={calendarFormat}
                                onChange={this.handleDateChange}
                                error={errors['end']}
                                disabled={disabled}
                              />
                          </div>
                          <div className={workHourClass}>
                            <div className="form-group">
                              <label>ระยะเวลา</label>
                              <h4><span className="badge badge-info p-2">{workHour}</span></h4>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
         );
    }
}
 
export default PlanDateSelector;