import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getTemplateType, getTemplateList } from '../../services/templateService';

// import _ from "lodash";
// import { Link } from "react-router-dom";
import SearchBox from "../common/searchBox";
// import { toast } from "react-toastify";
import TemplateCardTable from "./templateCardTable";

class Templates extends Component {

  state = {
    templates: [],
    types: [],
    pageSize: 10,
    currentPage: 1,
    searchQuery: "",
    selectedType: null,
    sortColumn: { path: "name", order: "asc" }
  };

  async componentDidMount() {
    this._isMounted = true

    this.loadTemplates()

  }

  componentWillUnmount() {  this._isMounted = false;  }

loadType = async () => {
  const { data: result } = await getTemplateType();
  if ( result.status === 'success') {
    if (this._isMounted) this.setState({ types: result.data.types})
  }
}

loadTemplates = async () => {
  const { data: result } = await getTemplateList();
  if ( result.status === 'success') {
    if(this._isMounted) this.setState({ templates: result.data.templates });
  }
}

  handleTypeSelect = type => {
    this.setState({ selectedType: type, searchQuery: "", currentPage: 1 });
  };

  handleSearch = query => {
    this.setState({ selectedType: null, searchQuery: query });
  };

  notifyUpdate = () => { this.loadTemplates();  }

  render() {
    const { searchQuery, templates } = this.state;
    const { user } = this.props;

    return (
      <React.Fragment>
        <div className="row mb-3">
          <div className="col-auto mr-auto">
          <h4>หัวข้อกิจกรรม</h4>
          </div>
          <div className="col-auto">
            {user && (
              <Link
                to="/template/new"
                className="btn btn-primary"
                style={{ marginBottom: 20 }}
              >
                เพิ่มหัวข้อกิจกรรม
              </Link>
            )}
          </div>
        </div>
        <div className="row mb-3 d-none">
          <div className="col-md-6 align-self-center">
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </div>
          <div className="col-md-6 align-self-center">
            <div className="row justify-content-end pr-3 d-none">
            </div>
          </div>
        </div>
        <TemplateCardTable data={templates} notifyUpdate={this.notifyUpdate} />
      </React.Fragment>
      
    );
  }
}

export default Templates;
