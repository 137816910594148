import http from "./httpService";

const apiEndpoint = "/option";
const manage = "/manage/"

export function getOptionData(id) {
  return http.get(apiEndpoint + manage + id);
}

export function addOptionData(id, data) {
  return http.put(apiEndpoint + manage  + id + "/add", data)
}

export function editOptionData(id, data) {
  return http.put(apiEndpoint + manage  + id + "/edit", data)
}

export function removeOptionData(id, data) {
  return http.put(apiEndpoint + manage  + id + "/remove", data)
}