import React from 'react';
import reviewGood from './../../assets/review/review_good.png';
import reviewNormal from './../../assets/review/review_normal.png';
import reviewBad from './../../assets/review/review_bad.png';

const EventCardAudience = ({data}) => {
    return ( <React.Fragment>
        {data && data.length>0 && <div className="card-text mt-4">
            <span><h5>ผู้เข้าร่วมกิจกรรม</h5></span>
            <div className="row">
                {data.map(item => 
                    { 
                        const review = (item.review) ? (item.review==='good') ? reviewGood : (item.review==='bad') ? reviewBad : reviewNormal : null;
                        
                        return (<div key={item._id} className="col-auto text-center mb-3">
                        <div className="reviewitem">
                            {review && <div className="review-badge"><img style={{width: "50px"}} src={review} alt={review} /></div>}
                            <img style={{width: "150px"}} src={item.thumbnail} alt={item._id} />
                        </div>
                        {item.name}
                        {(item.addby && item.addby!==item.name) ? <span className="text-muted"><br />เพิ่มโดย {item.addby}</span> : ""}
                    </div>)
                    }
                    )}
                </div>
            </div>}
    </React.Fragment> );
}
 
export default EventCardAudience;
