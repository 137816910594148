import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { Link } from 'react-router-dom';
import { saveTemplate, getTemplateType, getTemplate } from "../../services/templateService";
import { toast } from 'react-toastify';
import ConfirmDelete from './../common/confirmDelete';

class TemplateForm extends Form {
  _isMounted = false;

  state = {
    data: {
      type: [],
      name: "",
      description: ""
    },
    types: [],
    errors: {},
    isModalDeleteOpen: false
  };

  schema = {
    _id: Joi.string(),
    type: Joi.array().min(1).max(1),
    name: Joi.string()
      .required()
      .label("ชื่อกลุ่มกิจกรรม"),
      description: Joi.string().required().label("รายละเอียด")
  };

  async componentDidMount() {
    this._isMounted = true;

    await this.loadTypes();

    const id = this.props.match.params.id;
    if ( id !== 'new') {
       await this.loadTemplate(id);
    }

  }

  componentWillUnmount() { this._isMounted = false;  }

  getAllData() {
    return {...this.state.data,...this.state.groups}
  }

  getAllSchema() {
    return {...this.schema, ...this.schemaExtend}
  }

  async loadTypes() {
    const { data: result } = await getTemplateType();

    if ( result.status === 'success') {
      if (this._isMounted) this.setState({types: result.data.types});
    }
  }

  async loadTemplate(id) {
    try {

      const { data } = await getTemplate(id);

      if ( data.status && data.status==='success') {

        const { template } = data.data;

        if( this._isMounted ) this.setState({ data: this.mapData(template) });
        
      } else {
          toast.error(data.message);
          this.props.history.replace("/template");
      }
      
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  mapData(data) {
    return {
      _id: data._id,
      type: [{value: data.tag}],
      name: data.name,
      description: data.description
    };
  }

  doSubmit = async () => {
    try {
      const { _id, type, name, description } = this.state.data;
      
      const typeValue = (type) ? type.find(x=>x).value : "unknown"
      const doc = {tag: typeValue, name: name, description: description }
      if ( _id ) doc._id = _id;

      // console.log('save', doc);
  
      const { data: result} = await saveTemplate(doc);
      if ( result.status && result.status==='success') {
        this.props.history.push({
          pathname: "/template"
        })
      } else {
        toast.error(result.message);
      }
      
      
    } catch(err) {
      toast.error("บันทึกข้อมูลไม่สำเร็จ")
    }

  };

  render() {
    const headerName = (this.props.match.params.id==='new') ? 'เพิ่มชื่อกลุ่มกิจกรรม' : 'แก้ไขชื่อกลุ่มกิจกรรม';
    const { types, isModalDeleteOpen } = this.state;

    return (
      <React.Fragment>
        <div className="container">
          <ConfirmDelete isOpen={isModalDeleteOpen} onConfirm={this.onDeleteConfirm} onCancel={this.onDeleteCancel} />
          <form action="" autoComplete="off" onSubmit={this.handleSubmit}>
            <div className="card">
              <div className="card-header">
                  {headerName}
              </div>
              <div className="card-body">
                  <span className="card-text">
                    {this.renderSingleSelectButton("type", "ประเภทกิจกรรม", types)}
                    {this.renderInput("name", "ชื่อกิจกรรม")}
                    {this.renderInputTextArea("description", "รายละเอียด")}
                  </span>
              </div>
              <div className="card-footer text-right">
                {this.renderButton("บันทึก")}
                <Link to="/template" className="btn btn-secondary ml-3">ยกเลิก</Link>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default TemplateForm;
