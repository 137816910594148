import React from 'react';
import Chart from 'react-apexcharts'

const ReportAudienceGroup = ({data}) => {

    if(!data) return (<div></div>)

    const {audienceGroupTag, audienceGroupArea} = data;

    const optionsTag = {
        labels: audienceGroupTag.labels,
        
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                  },
                  value: {
                  }
                }
              }
            }
          }
      }

    const seriesTag = audienceGroupTag.data

    const optionsArea = {
        labels: audienceGroupArea.labels,
        
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                  },
                  value: {
                  }
                }
              }
            }
          }
      }

    const seriesArea = audienceGroupArea.data

    const showTag = (seriesTag.find(x=>x>0)) ? true : false;
    const showArea = (seriesArea.find(x=>x>0)) ? true : false;

    return ( 
        <div className="row">
            <div className="col-12 col-md-6 mb-3">
        <div className="card">
                <div className="card-header text-center">
                    จำนวนผู้เข้าร่วมตามประเภท
                </div>
                <div className="card-body text-center">
                    {showTag && <Chart options={optionsTag} series={seriesTag} type="donut" />}
                    {!showTag && <span className="text-muted">ไม่มีข้อมูล</span>}
                </div>
            </div>
        </div> 
        <div className="col-12 col-md-6 mb-3">
        <div className="card">
                <div className="card-header text-center">
                    จำนวนผู้เข้าร่วมตามพื้นที่
                </div>
                <div className="card-body text-center">
                    {showArea && <Chart options={optionsArea} series={seriesArea} type="donut" />}
                    {!showArea && <span className="text-muted">ไม่มีข้อมูล</span>}
                </div>
            </div>
        </div> 
        </div>
    );

}
 
export default ReportAudienceGroup;