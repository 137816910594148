import http from "./httpService";
// import moment from 'moment';

const apiEndpoint = "/work";

export function getLive() {  return http.get(apiEndpoint + "/live");}

export function getHistory(date, tag) {
  return http.get(apiEndpoint + "/history/?date=" + date + "&tag=" + tag );
}

export function getTemplate(id) {  return http.get(apiEndpoint + "/" + id); }

export function getWork(id) { return http.get(apiEndpoint + "/" + id )}

export function getAudience(id) { return http.get(apiEndpoint + "/" + id + "/audience")}

export function checkZoom(id) { return http.get(apiEndpoint + "/zoom/check/" + id)}

export function saveTemplate(template) {
  if (template._id) {
    const body = { ...template };
    delete body._id;
    return http.put(apiEndpoint + "/" + template._id, body);
  }

  return http.post(apiEndpoint, template);
}

export function deleteTemplate(id) {
  return http.delete(apiEndpoint + "/" + id);
}