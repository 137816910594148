import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Sidebar from "react-sidebar";

import auth from "./services/authService";

import NavBar from "./components/menu/navBar";
import Live from './components/live/live';
import History from './components/history/history';
import Work from './components/work/work';
import Templates from "./components/template/templates";
import NotFound from "./components/notFound";
import Staff from './components/staff/staffs';

import LoginForm from "./components/session/loginForm";
import Logout from "./components/session/logout";
import ProtectedRoute from "./components/common/protectedRoute";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import PlanForm from './components/plan/planForm';
import Plans from "./components/plan/plan";
import Profile from './components/user/profile';
import StaffForm from './components/staff/staffForm';
import TemplateForm from './components/template/templateForm';
import SideNavBar from "./components/menu/sideBar";
import OptionManager from "./components/options/manage";
import Branches from "./components/branch/branches";

const mql = window.matchMedia(`(min-width: 992px)`);

class App extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      sidebarDocked: mql.matches,
      sidebarOpen: false
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }

  componentDidMount() {
    mql.addListener(this.mediaQueryChanged);

    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  toggleSideBar = () => {


    const { sidebarOpen } = this.state;
    this.setState({ sidebarOpen: !sidebarOpen })
  }

  render() {
    const { user } = this.state;

    return (
      <React.Fragment>
        <ToastContainer />
        <NavBar user={user} onSidebarToggle={this.toggleSideBar} />
        <Sidebar
          sidebar={<SideNavBar />}
          open={this.state.sidebarOpen && (user!==undefined)}
          docked={this.state.sidebarDocked && (user!==undefined)}
          onSetOpen={this.onSetSidebarOpen} 
          styles={{root: {top: 0}, sidebar: {overflow: 'hidden'}}}
          >
          <div className="container-fluid">
            <div className="row justify-content-center">
              <main role="main" className="container-fluid" style={{padding: "43px", marginTop: "25px"}}>
                <Switch>
                  <ProtectedRoute path="/live" component={Live} />

                  <ProtectedRoute path="/history" component={History} />
                  <ProtectedRoute path="/work/:id" component={Work} />

                  <ProtectedRoute path="/plan" exact render={props => <Plans {...props} user={user} />} />
                  <ProtectedRoute path="/plan/:id" exact component={PlanForm} />

                  <ProtectedRoute path="/template" exact render={props => <Templates {...props} user={user} />} />
                  <ProtectedRoute path="/template/:id" exact component={TemplateForm} />} />

                  <ProtectedRoute path="/option/manage/:id" exact component={OptionManager} />} />
                  <ProtectedRoute path="/branch" exact render={props => <Branches {...props} user={user} />} />

                  <ProtectedRoute path="/staff" exact render={props => <Staff {...props} user={user} />} />
                  <ProtectedRoute path="/staff/:id" exact component={StaffForm} />

                  <ProtectedRoute path="/profile" render={props => <Profile {...props} user={user} />} />
                  <Route path="/login" component={LoginForm} />;
                  <ProtectedRoute path="/logout" component={Logout} />;
                  
                  <Route path="/not-found" component={NotFound} />
                  <Redirect from="/" exact to="/live" />
                  <Redirect to="/not-found" />
                </Switch>
              </main>
            </div>
          </div>
        </Sidebar>
      </React.Fragment>
    );
  }
}

export default App;
