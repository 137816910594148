import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { toast } from "react-toastify";
import { saveSite } from '../../services/areaService';

class EditSiteForm extends Form {
  _isMounted = false;

  state = {
    data: {
      _id: "",
      name: ""
    },
    errors: {}
  };

  schema = {
    _id: Joi.string().required(),
    name: Joi.string().required().label("Site Name")
  };

  componentDidMount() {
    const { data } = this.props;
    if ( data ) this.setState({data: {_id: data._id, name: data.name}});
  }

  componentWillUnmount() { this._isMounted=false; }

  getAllData() {
    return {...this.state.data}
  }

  getAllSchema() {
    return {...this.schema}
  }

  doSubmit = async () => {
    const { notifyUpdate }= this.props;
    const doc = this.state.data;

    try {
      const { data: result } = await saveSite(doc);
      if ( result.status === 'success') {
        
        notifyUpdate()
      } else {
        toast.error("Error : " + result.message);
      }
    } catch(err) {
      toast.error("ผิดพลาด : " + err.message);
    }
  };

  render() {
    const name = "name";
    const error = this.state.errors[name];
    const { name: inputText } = this.state.data;
    const { cancalEdit } = this.props;

    return (
      <React.Fragment>
        <div className="input-group">
          <input type="text" id={name} name={name} value={inputText} className="form-control col-md-8 col-lg-6" placeholder="แก้ไขชื่อพื้นที่" onChange={this.handleChange} />
          <div className="input-group-append">
            <button className="btn btn-outline-primary" disabled={this.validate()} onClick={this.handleSubmit}>บันทึก</button>
            <button className="btn btn-outline-secondary" onClick={() => cancalEdit()}>ยกเลิก</button>
          </div>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
      </React.Fragment>
    );
  }
}

export default EditSiteForm;
