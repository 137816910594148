import React from 'react';

import iconZoom from './../../assets/image/icon_zoom.png'

const EventCardZoom = ({data}) => {

    return ( <React.Fragment>
        {data && data.length>0 && 
        <div className="card-text mt-4">
            <span><h5>Zoom</h5></span>
            
                {data.map(item =>


                    <div key={item._id} className="card mb-3">
                        <div className="row no-gutters align-items-top justify-content-start p-3">
                            <div className="col-auto text-center">
                                <img src={iconZoom} style={{width: "80px", backgroundColor: "#EEEEEE66"}} 
                                    className="card-img-top p-1" alt="Zoom Icon" />
                                
                            </div>
                            <div className="col-auto">
                                <div className="card-body text-black py-0">
                                    <h5 className="d-inline">Meeting ID <span className={"badge badge-info"}>{item.meetingId}</span></h5>
                                    {item.owner==='join' && <h5 className="d-inline ml-2"><span className={"badge badge-warning"}>ผู้ร่วมกิจกรรม</span></h5>}
                                    <p className="card-text">
                                        เริ่มต้น <strong>{item.start}</strong><br />
                                        ระยะเวลา <strong>{item.minute}</strong> นาที
                                    </p>
                                </div>
                            </div>
                            <div className="col-auto ml-auto">
                                {item.isLive && <h5 className="d-inline"><a href={item.join} target="_blank" rel="noopener noreferrer" className={"badge badge-pill badge-danger"}>LIVE</a></h5>}
                                {item.isRecord && <h5 className="d-inline"><a href={item.play} target="_blank" rel="noopener noreferrer" className={"badge badge-pill badge-info"}>RECORD</a></h5>}
                                {item.isManual && <h5 className="d-inline"><span className={"badge badge-pill badge-secondary"}>MANUAL</span></h5>}
                            </div>
                        </div>
                    </div>
                )}
        </div>}
    </React.Fragment> );
}
 
export default EventCardZoom;
