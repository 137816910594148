import http from "./httpService";

const apiEndpoint = "/staff";

export function searchStaff(search, index) {
  // var options = {
  //     host: 'api.github.com',
  //     path: encodeURIComponent('/users/'+ username +'/repos'),
  //     method: 'GET'
  // };

  return http.get(apiEndpoint+ "/search/?search=" + encodeURIComponent(search) + "&index=" + index);
}

export function getStaffs() {
  return http.get(apiEndpoint);
}

export function getStaff(id) {
  return http.get(apiEndpoint + "/" + id);
}

export function createStaff(doc) {

  const data = doc;
  return http.post(apiEndpoint , data )
}

export function saveStaff(doc) {
  if (doc._id) {
    const body = { ...doc };
    delete body._id;
    return http.put(apiEndpoint + "/" + doc._id, body);
  }

  return createStaff(doc);
}

export function enableStaff(id) {
  return http.put(apiEndpoint + "/" + id + "/enable" );
}

export function disableStaff(id) {
  return http.put(apiEndpoint + "/" + id + "/disable" );
}

export function deleteStaff(id) {
  return http.delete(apiEndpoint + "/" + id);
}