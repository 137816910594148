import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import FontAwesome from 'react-fontawesome';
import { toast } from 'react-toastify';
import { getCurrentUser } from '../../services/authService';
import ConfirmDelete from '../common/confirmDelete';
import { deleteTemplate, enableTemplate, disableTemplate } from '../../services/templateService';

class TemplateCard extends Component {
    _isMounted = false;

    state = { 
        data: {},
        tokenStatus: '',
        isModalDeleteOpen: false
    }

    componentDidMount() {
        this._isMounted = true;

        const data = this.props.item;
        this.setState({ data });
    }

    componentWillUnmount() { this._isMounted = false; }


    handleEnable = async () => {
        const data= {...this.state.data}
        data.status = true
        this.setState({ data })

        const { _id } = this.state.data;

        if ( _id ) {
            try {
                await enableTemplate(_id);
                // this.notifyUpdateArea();
            } catch(err) {}
        } else {
            toast.error("ไม่พบข้อมูล area id")
        }
    }

    handleDisable = async () => {
        const data= {...this.state.data}
        data.status = false
        this.setState({ data })

        const { _id } = this.state.data;

        if ( _id ) {
            try {
                await disableTemplate(_id);
                // this.notifyUpdateArea();
            } catch(err) {}
        } else {
            toast.error("ไม่พบข้อมูล area id")
        }

    }

  askDeleteArea = () => {
    this.setState({isModalDeleteOpen: true })
  }

  onDeleteCancel = () => {
    this.setState({isModalDeleteOpen: false })
  }

  onDeleteConfirm = async () => {
    this.setState({isModalDeleteOpen: false})

    const { _id } = this.state.data;

    if ( _id ) {
        try {
            await deleteTemplate(_id);

            const { notifyUpdate } = this.props
            if ( typeof notifyUpdate === 'function') {
                notifyUpdate();
            }

        } catch(err) {
            toast.error("ผิดพลาด " + err.message)
        }
    } else {
        toast.error("ไม่พบข้อมูล area id")
    }
  }

  render() { 

    const { _id, name, description, tag, status } = this.state.data;
    const { isModalDeleteOpen } = this.state;

    const user = getCurrentUser();

    if (!_id) return (<div></div>);

    const classHeader = (status) ? 'card-header bg-info' : 'card-header bg-secondary'

    const classButtonEnable = 'btn btn-primary btn-sm'
    const classButtonDisable = 'btn btn-outline-secondary btn-sm'

    return ( 
        <React.Fragment>
            <ConfirmDelete isOpen={isModalDeleteOpen} onConfirm={this.onDeleteConfirm} onCancel={this.onDeleteCancel} />
            <div className="card mb-3" style={{width: '300px'}}>
                <div className={classHeader}>
                    <h5><Link className="text-white" to={`/template/${_id}`}>{name}</Link></h5>
                </div>
                <div className="card-body">
                    <h5 className="card-title">
                        {description}
                    </h5>
                    <div className="card-text">
                        ประเภท {tag}
                    </div>
                </div>
                <div className="card-footer text-right small p-2">
                    <div className="btn-group">
                        <button type="button" className={(status) ? classButtonEnable : classButtonDisable} onClick={this.handleEnable}>เปิด</button>
                        <button type="button" className={(!status) ? classButtonEnable : classButtonDisable} onClick={this.handleDisable}>ปิด</button>
                    </div>
                    {user.empId==='10082209' && <button type="button" className='btn btn-danger btn-sm text-white ml-2' onClick={this.askDeleteArea}>ลบ</button>}
                </div>
            </div>
        </React.Fragment>
        
     );
}
}
 
export default TemplateCard;