import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Select from "./select";
import Radio from "./radio";
import MyCalendar from "./calendar";
import TimePicker from './timePicker';
import SelectButton from "./SelectButton";
import ListButton from "./listButton";
import InputTextArea from "./inputTextArea";

class Form extends Component {
  state = {
    data: {},
    errors: {}
  };

  validate = () => {
    
    const options = { abortEarly: false };
    const allData = this.getAllData();
    const joiResult = Joi.validate(allData, this.getAllSchema(), options);

    const { error } = joiResult;
    if (!error) {
      if ( typeof this.validateExtend === 'function') {
        return this.validateExtend();
      } else {
        return null;
      }
    } else {
      const errors = {};
      for (let item of error.details) {
        errors[item.path[0]] = item.message;
      }
      // console.log(errors)
  
      return errors;
    }

    
  };

  validateProperty = ({ name, value }) => {
    try {
      const obj = { [name]: value };
      const schema = { [name]: this.getAllSchema()[name] };
  
      const options = { abortEarly: true };
      const { error } = Joi.validate(obj, schema, options);
  
      return error ? error.details[0].message : null;
    } catch(err) {
      // console.log('validateProperty', name, value, err);

      return err.message;
    }
    
  };

  handleChange = ({ currentTarget: input }) => {
    // console.log("handleChange", input);

    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    if ( input.name in data) {
      
      const errorMessage = this.validateProperty(input);
  
      if (errorMessage) errors[input.name] = errorMessage;
      else delete errors[input.name];

      data[input.name] = input.value;
      this.setState({ data, errors });

    }  

    if (typeof this.handleChangeExtend === "function") { 
      this.handleChangeExtend(input, data, errors);
    }
    
  };

  handleSingleSelectButton = ({name, item}) => {

    const data = {...this.state.data};
    const value = [];
    value.push({value: item._id});
    
    data[name] = value;

    const errors = { ...this.state.errors };
    errors[name] = (value.length===1) ? null : "Please select 1 item"

    this.setState({ data, errors })

    if (typeof this.handleSingleSelectExtend === "function") { 
      this.handleSingleSelectExtend(name, item, data, errors);
    }
  }

  handleMultiSelectButton = input => {
    const data = {...this.state.data};
    let { branch } = data;

    branch = (branch.filter(x=>x.value==='all').length>0) ? [] : branch;

    if ( branch.filter(x => x.value===input.value).length >0) {
      branch = branch.filter(x=> x.value!==input.value)
    } else {
      branch.push({value: input.value});
    }

    branch = (branch.filter(x=>x.value==='all').length>0) ? [{name:'All', value:'all'}] : branch;

    data.branch = branch;

    const errors = { ...this.state.errors };
    errors.branch = (branch.length>0) ? null : "Branch can not empty"

    this.setState({ data, errors })
  }
  

  handleSubmit = e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) return;

    this.doSubmit();
  };

  renderInput(name, label, type = "text", placeHolder= "") {
    const { data, errors } = this.state;

    return (
      <Input
        name={name}
        value={data[name]}
        label={label}
        placeholder={placeHolder}
        onChange={this.handleChange}
        type={type}
        error={errors[name]}
      />
    );
  }

  renderInputTextArea(name, label, type = "text", placeHolder= "") {
    const { data, errors } = this.state;

    return (
      <InputTextArea
        name={name}
        value={data[name]}
        label={label}
        placeholder={placeHolder}
        onChange={this.handleChange}
        type={type}
        error={errors[name]}
      />
    );
  }

  renderRadio(name, label, type = "radio") {
    const { data, errors } = this.state;

    return (
      <Radio
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        type={type}
        error={errors[name]}
      />
    );
  }

  renderSelect(data, name, label, options, isEditable=true, handleChange=this.handleChange ) {
    const { errors } = this.state;

    const value = (data[name]) || "";

    return (
      <Select
        name={name}
        value={value}
        label={label}
        options={options}
        onChange={handleChange}
        error={errors[name]}
        disabled={!isEditable}
      />
    );
  }

  renderListButton(data, name, label, options, isEditable=true, handleChange=this.handleChange ) {
    const { errors } = this.state;

    const value = (data[name]) || "";

    return (
      <ListButton
        name={name}
        value={value}
        label={label}
        options={options}
        onChange={handleChange}
        error={errors[name]}
        disabled={!isEditable}
      />
    );
  }

  renderSingleSelectButton(name, label, options) {
    const { data, errors } = this.state;

    return (
      <SelectButton
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onSelect={this.handleSingleSelectButton}
        error={errors[name]}
      />
    )
  }

  renderMultiSelectButton(name, label, options) {
    const { data, errors } = this.state;

    return (
      <SelectButton
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onSelect={this.handleMultiSelectButton}
        error={errors[name]}
      />
    )
  }

  renderDate(id, name, label, options, value, handleChange=this.handleChange) {
    const { errors } = this.state;

    return (
      <MyCalendar
        id={id}
        name={name}
        value={value}
        label={label}
        options={options}
        onChange={handleChange}
        error={errors[name]}

      />
    )
  }

  renderTime(name, label, options, data) {
    const { errors } = this.state;

    return (
      <TimePicker
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}

      />
    )
  }

  renderButton(label) {
    return (
      <button disabled={this.validate()} className="btn btn-primary">
        {label}
      </button>
    );
  }

  renderRedButton(label, onClick) {
    return (
      <button 
        type="button"
        onClick={onClick}
        className="btn btn-danger">
        {label}
      </button>
    );
  }
  
}

export default Form;
