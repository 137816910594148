import React, { Component } from "react";
import LiveCard from './liveCard';

class LiveCardTable extends Component {

  render() {
    const { data } = this.props;

    return (
      <div className="row">
        {data && data.map( (item,index) => {
          return (
            <React.Fragment key={item._id}>
              {((index!==0 && item.start_date && (data[index-1].start_date!==item.start_date))|| index===0) && 
                <div className="col-12 mb-2">
                  <div className="alert alert-primary" role="alert">
                    <div className="row">
                      <div className="col-auto"><div>วันที่ {item.start_date}</div></div>
                      <div className="col-auto">จำนวน {data.filter(x=> x.start_date===item.start_date).length} รายการ</div>
                    </div>
                  </div>
                </div>}
              <div className="col-12">
                <LiveCard item={item} />
              </div>
            </React.Fragment>
          )
        })}
      </div>
    );
  }
}

export default LiveCardTable;
