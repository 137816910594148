import React, { Component } from "react";
import Table from "../common/table";
import { Link } from "react-router-dom";

class PlanTable extends Component {
  columns = [
    { path: "start_text", label: "วันที่-เวลา", className: 'd-none d-md-table-cell' },
    { path: "start_text_short", label: "วันที่-เวลา", className: 'd-table-cell d-md-none' },
    { path: "name", label: "ชื่องาน", content: template => <Link to={`/plan/${template._id}`}>{template.name}</Link>, className: '' },
    { path : "type", label: "ประเภท", className: 'd-none d-sm-none d-md-table-cell'},
    { path : "branch", label: "สาขา", className: 'd-none d-sm-none d-md-table-cell'},
    { path: "workers", label: "ผู้ปฏิบัติงาน", className: ''},
    { path: "status", label: "สถานะ", content: template => (template.workId) ? <Link to={`/work/${template.workId}`}>{template.status}</Link> : template.status, className: ''}
  ];

  render() {
    const { data, onSort, sortColumn } = this.props;

    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          onSort={onSort}
          data={data}
          sortColumn={sortColumn}
        />
      </React.Fragment>
    );
  }
}

export default PlanTable;
