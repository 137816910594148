import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";

import { getTemplateType, getTemplateList, getTemplate } from "../../services/templateService";
import { savePlan, getPlan, deletePlan } from "../../services/planService";
import _ from 'lodash';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { getStaffs } from "../../services/staffService";
import ConfirmDelete from "../common/confirmDelete";
import { toast } from "react-toastify";
import StaffSelector from "./staffSelector";
import PlanDateSelector from "./planDateSelector";
import { Link } from 'react-router-dom';

class PlanForm extends Form {
  _isMounted = false;

  state = {
    data: {
      type: "",
      template: "",
      status: 'wait',
      staffs: [],
      dates: [
        {id: Date.now().toString(), mode:'one', start: moment(new Date()).startOf('hour').toDate(),
        end: moment(new Date()).startOf('hour').add(2, 'hours').toDate(), errors:{}}
      ]
    },
    groups: {},
    types: [],
    templates: [],
    templateContent: {},
    staffs: [],
    isModalDeleteOpen: false,
    errors: {}
  };

  getAllData() {
    return {...this.state.data,...this.state.groups}
  }

  getAllSchema() {
    return {...this.schema, ...this.schemaExtend}
  }

  
  schema = {
    _id: Joi.string(),
    type: Joi.string().required(),
    template: Joi.string().required(),
    status: Joi.string().required(),
    staffs: Joi.array().min(0),
    dates: Joi.array().min(1)
  };

  schemaExtend = {
  };

  async componentDidMount() {
    this._isMounted = true;

    await Promise.all([
      this.loadTypes(),
      this.loadTemplates(),
      this.loadStaffs()]
    )

    const planId = this.props.match.params.id;
    if ( planId !== 'new') {
       await this.loadPlan(planId);
    }
  }

  componentWillUnmount() { this._isMounted = false;  }

  async loadTypes() {
    const { data: result } = await getTemplateType();

    if ( result.status === 'success') {
      const typeList = result.data.typeList.filter(x => x._id !== 'emergency');
      this.setState({types: typeList});
    }
  }

  async loadTemplates() {
    const { data: result } = await getTemplateList();
    if ( result.status === 'success') {
      const list = result.data.templates.filter(x => x.type !== 'emergency');
      this.setState({templates: list});
    }
  }

  async loadStaffs() {
    const { data } = await getStaffs();

    if ( data.status === 'success') {
      const staffs = data.data.staffs;
      this.setState({ staffs });
    }
  }

  handleChangeExtend = (input, data, errors) => {
    // console.log(input);

    const errorMessage = this.validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    //Reset Template when change Type
    const { type, template } = this.getAllData();
    if ( input.name === 'type' && template !== "") {
      const errorMessage2 = this.validateProperty({name: "template", value: ""});

      if (errorMessage2) errors["template"] = errorMessage2;
      else delete errors["template"];

      data["template"] = "";
    }

    //Auto Select Type when select Template
    if ( input.name === 'template' && !type) {
      const { types, templates } = this.state;

      if ( types && templates) {
        const getTemplates = templates.filter(x => x._id===input.value);
        if ( getTemplates.length > 0 ) {
          const type = getTemplates[0].type;

          if ( type) {
            data["type"] = type;
            errors["type"] = null
          }
        }
      }
    }

    //Change Template If Diff
    if ( input.name === 'template' && input.value) {
      if ( this.state.templateContent==={} || this.state.templateContent._id !== input.value) {
        this.loadTemplateContent(input.value);
      } 
    }

    this.setState({ data, errors });

  }

  validateExtend = () => {
    const { dates } = this.state.data;
    return (dates.filter(x=> x.errors && !_.isEmpty(x.errors)).length>0) ? "Some date items is invalid" : null;
  }

  handleSelectGroupChange = ({ currentTarget: input }) => {

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = {...this.state.data}
    const groups = { ...this.state.groups };
    groups[input.name] = input.value;

    //Reset Route when change Branch
    const { route, branch } = groups;
    if ( route ) {
      if ( input.name === 'branch' && route !== "") {

        const errorMessage2 = this.validateProperty({name: "route", value: ""});
  
        if (errorMessage2) errors["route"] = errorMessage2;
        else delete errors["route"];

        groups["route"] = "";
      }
    }

    //Auto Select Branch when select Route
    if ( input.name === 'route' && !branch) {
      const getBranch = this.getBranchFromRouteFilter(input.value);
      
      groups["branch"] = getBranch || null;

      if (getBranch) errors["branch"] = null;

    }

    //Prepare Area when select branch
    if ( input.name === 'branch' && this.schemaExtend.area ) {
      
      let errorMessage2 = this.validateProperty({name: "area", value: ""});
      if ( errorMessage2==='"area" is not allowed to be empty') errorMessage2 = "โปรดเลือกพื้นที่ปฏิบัติงาน"
      if (errorMessage2) errors["area"] = errorMessage2;
      else delete errors["area"];

      groups["area"] = "";

      const areas = this.getFilterAreas(input.value);

      if ( !areas) {
        errors['area'] = "Area is not found"
      } 
    }

    //Recheck Selected Staffs
    if ( input.name === 'branch' && data.staffs && data.staffs.length>0) {
      const { staffs } = data;

      const checkSelected = staffs.filter(x => this.state.staffs.filter(i=>i.branchValue===input.value).filter(y => y.empId===x.empId).length>0);

      data.staffs = checkSelected;
    }

    this.setState({ data, groups, errors });
  };


  async loadTemplateContent(id) {
    const { data } = await getTemplate(id);

    if ( data.status === 'success') {

      this.schemaExtend = {}

      const group = data.data.template.options;
      group.map(item => {
        this.schemaExtend[item.tag] = Joi.string().required()
        return item;
      })

      this.setState({templateContent: data.data.template, groups: {}});
    }
  }

  async loadPlan(planId) {
    try {

      const { data } = await getPlan(planId);
      // console.log(data);

      if ( data.status && data.status==='success') {
        const { plan } = data.data;

        await this.loadTemplateContent(plan.template);

        const errors = { ...this.state.errors };

        this.setState({ data: this.mapData(plan), groups: this.mapGroup(plan.options), errors: errors });
      } else {
          toast.error("Plan is not found");
          this.props.history.replace("/plan");
      }
      
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  getFilteredTemplates() {
    const { type } = this.state.data;
    const { templates} = this.state;
    return (type) ?
      templates.filter(x => x.type===type) : templates
  }

  getFilterAreas(branch) {
    const { options } = this.state.templateContent;

    if ( branch && options ) {
        const findBranch = options.find(x=>x.tag==='branch');
        if ( findBranch && findBranch.choice ) {
          const choice = findBranch.choice.find(x=>x.value===branch);
          if ( choice && choice.areas) {
            return choice.areas
          }
        }
    } 

    return null;
  }

  getBranchFromRouteFilter(input) {
    const { options } = this.state.templateContent;

    let getBranch = ""

    options.forEach( item => {
      // console.log("item", item);
      item.choice.forEach( x => {
        if ( x._id === input) {
          getBranch = x.filter.site;
          
        }
      })
    });

    return getBranch;
  }

  mapData(data) {
    return {
      _id: data._id,
      type: data.type,
      template: data.template,
      status: data.status,
      dates: data.schedules,
      staffs: data.workers
    };
  }

  mapGroup(group) {
    if ( group) {

      const groups = {};
      group.forEach(x => {
          groups[x.tag] = x.select[0].value;
      });
      return groups;

    } else { return {} }

  }

  notifyChangeStaff = ({items}) => {
    const data = { ...this.state.data };
    data.staffs = items;

    if ( this._isMounted) this.setState({ data});
  }

  notifyChangeDates = ({items}) => {
    // console.log('get date notify', items);

    const data = {...this.state.data};
    data.dates = items;

    if (this._isMounted) this.setState({ data });
  }

  doSubmit = async () => {
    try {
      const { _id, type, template, staffs, dates } = this.state.data;

      const group = Object.keys(this.state.groups).map(item => {
        return {tag: item, select: [{  value: this.state.groups[item] }] };
      })
  
      const findType = this.state.types.filter(x => x._id===type);
      const typeName = ( findType.length >0) ? findType[0]._id : type;
  
      const findName = this.state.templates.filter(x => x._id===template);
      const templateName = ( findName.length>0) ? findName[0].name : template;
  
      const doc = { _id: _id, template: template, type: typeName, name: templateName, 
        schedules: dates, options: group, workers: staffs };
  
      await savePlan(doc);
      
      this.props.history.push({
        pathname: "/plan"
      })
    } catch(err) {
      toast.error("บันทึกแผนงานไม่สำเร็จ")
    }
    
  }

  doDelete = async () => {
    this.setState({isModalDeleteOpen: true})
  }

  onDeleteCancel = () => {
    this.setState({isModalDeleteOpen: false})
  }

  onDeleteConfirm = async () => {
    this.setState({isModalDeleteOpen: false})

    const { _id } = this.state.data;

    if ( _id ) {
      try {
        await deletePlan(_id)
        this.props.history.replace("/plan");
      } catch(err) {

      }
      
    }
  }

  render() {
    const headerName = (this.props.match.params.id==='new') ? 'สร้างแผนงานใหม่' : 'แก้ไขแผนงาน';

    const { types } = this.state;
    const { _id, type, template, status, staffs: selectedStaffs =[], dates } = this.state.data;

    const filteredTemplates = this.getFilteredTemplates();
    const { options: group } = this.state.templateContent;

    const { branch: selectBranch, area : selectArea } = this.state.groups;
    const areas = this.getFilterAreas(this.state.groups.branch);

    if ( group && areas) {
      group.forEach(item => {
        if(item.tag==="area") item.choice = areas;
      })
    }

    const { isModalDeleteOpen } = this.state;
    const isEditable = (status==='wait') ? true : false;
    
    // console.log(this.validate());
    // console.log(this.state.data.dates);

    return (
      <React.Fragment>
          <ConfirmDelete isOpen={isModalDeleteOpen} onConfirm={this.onDeleteConfirm} onCancel={this.onDeleteCancel} />
          <div className="row mb-4">
            <div className="col-auto mr-auto"><h4>{headerName}</h4></div>
            <div className="col-auto d-lg-none"><FontAwesome name="times-circle" size="2x" onClick={() => this.props.history.push({pathname: "/plan"})} /></div>
          </div>
          <form action="" autoComplete="off" onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-xs-12 col-sm-4">{this.renderSelect(this.state.data, "type", "Type", types, isEditable )}</div>
              <div className="col-xs-12 col-sm-8">{this.renderSelect(this.state.data, "template", "Template", filteredTemplates, isEditable )}</div>
            </div>
            {type && template && group &&
              group.map(item => {
                console.log(item);
                // switch(item.tag) {
                //   case "branch": {
                    
                //   }
                //   case "area": {

                //   }
                //   case "name": {
                    
                //   }
                //   case "detail": {
                    
                //   }
                // }
                if (item.tag==='area' && (!areas || areas.length===0)) {
                  if ( selectBranch) {
                    return( <div key={item.tag}  className="row mt-3"><div className="col"><div className="alert alert-danger">โปรดเพิ่มข้อมูล พื้นที่ สำหรับสาขานี้ อย่างน้อย 1 พื้นที่</div></div></div> )
                  } else {
                    return( <div key={item.tag}  className="row mt-3"><div className="col"><div className="alert alert-danger">โปรดเลือกข้อมูลสาขา</div></div></div> )
                  }
                } else {
                  if ( item.choice && item.choice.length>0) {
                    const choices = item.choice.map(x => {
                      if ( item.tag === "branch") x._id=x.value;
                      else x.value=x._id;
                      
                      return x;
                    }).filter(y => (!this.state.groups.branch)  || (!y.filter) || (!y.filter.site)  || (y.filter.site===this.state.groups.branch));
  
                    return(<div key={item.tag} className="row mt-3">
                      <div className="col">
                        {this.renderSelect(this.state.groups, item.tag, item.name, choices, isEditable, this.handleSelectGroupChange )}
                      </div>
                    </div>)
                  } else {
                    return (<div key={item.tag}></div>);
                  }
                  
                }
              })
            }
            { (type && template) &&
              <React.Fragment>
                <PlanDateSelector dates={dates} notifyChange={this.notifyChangeDates} disabled={!isEditable}/>
                {selectBranch && selectArea && 
                <StaffSelector 
                    key={selectBranch+selectArea} header="ผู้ปฏิบัติงาน" emptyText="เลือกผู้ปฏิบัติงานได้จากรายชื่อพนักงานด้านล่าง" 
                    items={this.state.staffs} selected={selectedStaffs} 
                    notifyChange={this.notifyChangeStaff} branch={selectBranch} area={selectArea}
                    disabled={!isEditable} />}
              </React.Fragment>
            }
            <div className="row col my-4 justify-content-center">
              {isEditable && this.renderButton("บันทึก")}
              {_id && isEditable && <div className="ml-4">{this.renderRedButton("ลบ", this.doDelete)}</div>}
              <Link to="/plan" className="btn btn-secondary ml-4">ยกเลิก</Link>
              {/* <button className="btn btn-secondary" onClick={() => {this.props.history.push({pathname: "/plan"})}}>ยกเลิก</button> */}
            </div>
            {!isEditable && <div className="row mt-4"><div className="col"><div className="alert alert-danger">แผนงานนี้ไม่สามารถแก้ไขได้ (Status={status})</div></div></div>}

          </form>
        
      </React.Fragment>
    );
  }
}

export default PlanForm;
