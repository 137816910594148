import React from 'react';

const EventCardComment = ({data}) => {
    const comment = (data) ? data.filter(x=>x.comment) : null
    // console.log(comment);

    return ( <React.Fragment>
        {comment && comment.length>0 && 
        <div className="card-text mt-4">
            <span><h5>ความคิดเห็นจากผู้ร่วมกิจกรรม</h5></span>
            
                {comment.map(item => 
                    <div key={item._id} className="row">
                        <div className="col-12">
                            <div className="alert alert-secondary" role="alert">
                            {item.comment}
                            </div>
                        </div>
                    </div>
                )}
        </div>}
    </React.Fragment> );
}
 
export default EventCardComment;
