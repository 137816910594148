import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { toast } from "react-toastify";
import { saveBusiness } from '../../services/areaService';

class CreateBusinessForm extends Form {
  _isMounted = false;

  state = {
    data: {
      name: ""
    },
    errors: {}
  };

  schema = {
    name: Joi.string().required().label("Business Name")
  };

  getAllData() {
    return {...this.state.data}
  }

  getAllSchema() {
    return {...this.schema}
  }

  doSubmit = async () => {
    const { notifyUpdate }= this.props;

    try {
      const doc = {...this.state.data};

      const { data: result } = await saveBusiness(doc);
      if ( result.status === 'success') {
        
        notifyUpdate()
        this.setState({ data: {name:""}})

      } else {
        toast.error("Error : " + result.message);
      }
    } catch(err) {
      toast.error("Error : " + err.message);
    }
  };

  render() {
    const name = "name";
    const error = this.state.errors[name];
    const { name: inputValue } = this.state.data;

    return (
      <React.Fragment>
        {/* <form action="" onSubmit={(this.handleSubmit)}> */}
          <div className="input-group">
            <input type="text" name={name} id={name} value={inputValue} className="form-control" placeholder="เพิ่มสายงาน" onChange={this.handleChange} />
            <div className="input-group-append">
              <button className="btn btn-outline-primary" disabled={this.validate()} onClick={this.handleSubmit}>เพิ่ม</button>
            </div>
          </div>
          {error && (!error.includes('is not allowed to be empty')) && <div className="alert alert-danger">{error}</div>}
        {/* </form> */}
      </React.Fragment>
    );
  }
}

export default CreateBusinessForm;
