import React, { Component } from 'react';
import SortableTree, { changeNodeAtPath, addNodeUnderParent, removeNodeAtPath, getVisibleNodeCount, getTreeFromFlatData } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import FontAwesome from 'react-fontawesome';

import SearchBox from "../common/searchBox";
import { getOptionData, addOptionData, editOptionData, removeOptionData } from './../../services/optionService';
import { toast } from 'react-toastify';
import ConfirmDelete from './../common/confirmDelete';

class OptionManager extends Component {
    _isMounted = false
    state = { 
      search: "",
      level: {},
      raw: [],
      treeData: [],
      isModalDeleteOpen: false,
      newRoot: {value: ''}
    }

    componentDidMount() {
      this._isMounted = true

      const { id } = this.props.match.params
      if(id) this.setState({ id })


      this.loadOptionData();
    }

    componentWillUnmount() {
      this._isMounted = false
    }

    loadOptionData = async() => {
      const { id } = this.props.match.params
      if(!id) return toast.error("id is not found")

      const { data } = await getOptionData(id);

      if ( data.status === 'success') {
        const { data: raw, level } = data.data;


        if ( this._isMounted) {
          const treeData = getTreeFromFlatData({
              flatData: raw.map(node => ({ ...node })),
              getKey: node => node._id, // resolve a node's key
              getParentKey: node => node.parent, // resolve a node's parent's key
              rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
          })
          
          // console.log(raw);
          // console.log(treeData)

          this.setState({ treeData, raw, level });
        } 
        
      } else {
        toast.error(data.message)
      }
    } 

    handleSearch = search => {
      this.setState({ search })
    }

    handleNewRootValue = value => {
      this.setState({newRoot: { value} })
    }

    confirmNewRoot = async ({node, path, getNodeKey}) => {
      try {
        const { id, newRoot } = this.state;
        if(!id) return toast.error("id is not found")

        if(!newRoot.value) return toast.error("input can not empty")

        const { treeData } = this.state
        const root = treeData.find(x=>x) || (this.state.level) ? {tag: Object.keys(this.state.level)[0]} : { tag: 'site'}

        const doc = {tag: root.tag, name: newRoot.value }

        const { data: result } = await addOptionData(id, doc)

        if ( result.status === 'success') {
          const { item } = result.data

          this.setState(state => ({
            treeData: addNodeUnderParent({
              treeData: state.treeData,
              getNodeKey,
              newNode: item
            }).treeData
          }))

          this.setState({ newRoot: {value: ''}})

        } else {
          toast.error(result.message)
        }

      } catch(err) {
        toast.error(err.message);
      }
    }

    askNewNode = ({node, path, getNodeKey}) => {
      console.log(this.state.level)
      const label = (this.state.level) ? this.state.level[node.childTag].name : "Item";
      const newNode = {_id: 'new', tag: node.childTag, label, parent: node._id, name: ''}

      this.setState(state => ({
        treeData: addNodeUnderParent({
          treeData: state.treeData,
          parentKey: path[path.length - 1],
          expandParent: true,
          getNodeKey,
          newNode: newNode,
          addAsFirstChild: state.addAsFirstChild,
        }).treeData
      }))

      this.setState({ askNew: newNode })
      
    }

    confirmNew = async ({node, path, getNodeKey}) => {
      try {
        const { id} = this.state;
        if(!id) return toast.error("id is not found")

        if(!node.name) return toast.error("input is empty")

        const doc = {tag: node.tag, name: node.name, parent: node.parent}

        const { data: result } = await addOptionData(id, doc)

        if ( result.status === 'success') {
          const { item } = result.data

          this.setState(state => ({
            treeData: changeNodeAtPath({
              treeData: state.treeData,
              path,
              getNodeKey,
              newNode: item,
            })
          }));

        } else {
          toast.error(result.message)
        }

      } catch(err) {
        toast.error(err.message);
      }
    }

    askEditNode = node => {
      this.setState({askEdit: node})
    }

    askEditChange = name => {
      this.setState({askEdit: {...this.state.askEdit, name}})
    }

    confirmEdit = async ({node, path, getNodeKey}) => {
      try {
        const { id} = this.state;
        if(!id) return toast.error("id is not found")

        if(!node.name) return toast.error("input is empty")

        const doc = {_id: node._id, tag: node.tag, name: node.name, parent: node.parent}

        const { data: result } = await editOptionData(id, doc)

        if ( result.status === 'success') {
          const { item } = result.data;

          this.setState(state => ({
            treeData: changeNodeAtPath({
              treeData: state.treeData,
              path,
              getNodeKey,
              newNode: item,
            })
          }));

          this.setState({ askEdit: null})

        } else {
          toast.error(result.message)
        }

      } catch(err) {
        toast.error(err.message);
      }

      
    }

    cancelEdit = ({node, path, getNodeKey}) => { 
      this.setState({askEdit: null }) 
      this.setState(state => ({
        treeData: changeNodeAtPath({
          treeData: state.treeData,
          path,
          getNodeKey,
          newNode: {...this.state.askEdit},
        })
      }));
    }

    askDelete = ({node, path, key}) => {
      this.setState({askDelete: {node, path, key}, isModalDeleteOpen: true})
    }

    confirmDelete = async () => {
      this.setState({ isModalDeleteOpen: false})

      try {
        const { id} = this.state;
        if(!id) return toast.error("id is not found")

        const { node, path, key} = this.state.askDelete

        if ( node && path && key) {
          const doc = {_id: this.getChildrenId(node) }

          const { data: result } = await removeOptionData(id, doc)
  
          if ( result.status === 'success') {
  
            this.setState(state => ({
              treeData: removeNodeAtPath({
                treeData: state.treeData,
                path,
                getNodeKey: key
              }),
            }))

            this.setState({ askDelete: null, isModalDeleteOpen: false})
  
          } else {
            toast.error(result.message)
          }
  
        } else {
          toast.error("invalid ask delete info");
        }
      } catch(err) {
        toast.error(err.message);
      }

      this.setState({ askEdit: null})
    }

    getChildrenId = (node) => {
      if(node.children && node.children.length>0) {
        const output = node.children.map(x => this.getChildrenId(x)).flat();

        return [node._id, ...output]
      } else {
        return [node._id]
      }
      
    }

    cancelDelete = () => { 
      this.setState({ askDelete: null, isModalDeleteOpen: false })
    }

      
    render() { 
      const { id, search, isModalDeleteOpen } = this.state;
      const getNodeKey = ({ treeIndex }) => treeIndex;

      const count = getVisibleNodeCount({treeData: this.state.treeData})
      
      return ( 
      <React.Fragment>
        <ConfirmDelete isOpen={isModalDeleteOpen} onConfirm={this.confirmDelete} onCancel={this.cancelDelete} />
        <div className="row mb-3">
          <div className="col-auto mr-auto">
            <h5>จัดการพื้นที่</h5>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 d-none">
            <SearchBox value={search} onChange={this.handleSearch} />
          </div>
        </div>
        
        {id && this.state.treeData && 
        <div className="container-fluid">
          
          
          <SortableTree
            style={{height: count * 62}} // height of each node
            treeData={this.state.treeData}
            onChange={treeData => this.setState({ treeData })}
            generateNodeProps={({ node, path }) => 
            {
              const { askEdit } = this.state

              // console.log(askNew);
              // console.log(node)
              const isChildable = (node.childTag)
              const isNew = (node._id==='new') || false
              const isEdit = (askEdit && askEdit._id===node._id) || false

              const buttonAdd = (!isEdit && isChildable) ?'inline' : 'none'
              const buttonMainFlag = (isEdit || isNew) ? 'none' : 'inline'
              const buttonEditFlag = (isEdit) ? 'inline' : 'none'
              const buttonNewFlag = (isNew) ? 'inline' : 'none'
              // console.log(buttonEditFlag)


              const titleObj = 
                (isNew)
                ? <div>{node.label} <input style={{ fontSize: '1.1rem', backgroundColor: '#FFC300' }} value={node.name} onChange={event => {
                    const name = event.target.value;

                    this.setState(state => ({
                      treeData: changeNodeAtPath({
                        treeData: state.treeData,
                        path,
                        getNodeKey,
                        newNode: { ...node, name },
                      }),
                    }))
                  }} placeholder='เพิ่มรายการใหม่' autoFocus /></div>
                : (isEdit) 
                ? <div>{node.label} <input style={{ fontSize: '1.1rem', backgroundColor: '#FFC300' }} value={node.name} onChange={event => {
                    const name = event.target.value;

                    this.setState(state => ({
                      treeData: changeNodeAtPath({
                        treeData: state.treeData,
                        path,
                        getNodeKey,
                        newNode: { ...node, name },
                      }),
                    }))
                  }} /></div>
                : <span >{node.label} : {node.name}</span>
            
              return ({
                canDrag: false,
                title: (titleObj),
                buttons: [
                  <button
                    style={{display: buttonMainFlag}}
                    onClick={() => this.askEditNode(node)}
                  ><FontAwesome name="wrench" /></button>,
                  <button
                    style={{display: buttonAdd}}
                    onClick={() => this.askNewNode({node, path, getNodeKey})}
                  ><FontAwesome name="plus-circle" /></button>,
                  <button
                    style={{display: buttonMainFlag}}
                    onClick={() => this.askDelete({node, path, key: getNodeKey})}
                  ><FontAwesome name="minus-circle" /></button>,
                  <button
                    style={{display: buttonEditFlag}}
                    onClick={() => this.confirmEdit({node, path, getNodeKey})}
                  ><FontAwesome name="check-circle-o" /></button>,
                  <button
                    style={{display: buttonEditFlag}}
                    onClick={() => this.cancelEdit({node, path, getNodeKey})}
                  ><FontAwesome name="ban" /></button>,
                  <button
                    style={{display: buttonNewFlag}}
                    onClick={() => this.confirmNew({node, path, getNodeKey})}
                  ><FontAwesome name="check-circle-o" /></button>,
                  <button
                    style={{display: buttonNewFlag}}
                    onClick={() =>
                      this.setState(state => ({
                        treeData: removeNodeAtPath({
                          treeData: state.treeData,
                          path,
                          getNodeKey,
                        }),
                      }))}
                  ><FontAwesome name="ban" /></button>,
                ],
              })
            }
          }
            />
          <div style={{maxWidth: 230, marginTop: 16, marginLeft: 44}}>
            <div className="input-group">
              <input type="text" value={this.state.newRoot.value} className="form-control" placeholder="เพิ่มพื้นที่ใหม่" onChange={event => this.handleNewRootValue(event.target.value)} />
              <div className="input-group-append">
                <button className="btn btn-outline-primary" onClick={this.confirmNewRoot}><FontAwesome name="plus-circle" /></button>
              </div>
            </div>
          </div>
        </div>}

      </React.Fragment> );
    }
}
 
export default OptionManager;