import React, { Component } from 'react';
import { getWork } from './../../services/workService';
// import PhotoGallery from './../common/photos';
// import PhotoGrid from './../common/photoGrid';
// import MessageList from '../common/messageList';
// import ZoomList from '../common/zoomList';
// import PhotoGallery from './../common/photoGallery';

import socketIOClient from './../../services/socketioService';
import EventCard from './eventCard';

class Work extends Component {
    _isMounted = false;

    state = { 
        id: "",
        name: "",
        type: "",
        status: "",
        group: [],
        messages: [],
        photos: [],
        zooms: [],
        owner: "",
        start: "",
        finish: ""
     }

    async componentDidMount() {
        this._isMounted = true;

        // await this.loadWork();

        this.setupSocketIO()

    }

    componentWillUnmount() {
        this._isMounted = false;
        socketIOClient.off('update-work-content');
    }

    async loadWork() {

        try {
            const id = this.props.match.params.id;
            if (id === "new") return;
      
            const { data } = await getWork(id);

            if ( data.status && data.status==='success') {
                const { work } = data.data;

                if ( this._isMounted) {

                    this.setState(
                    {
                        id: id,
                        name: work.name,
                        type: work.type,
                        status: work.status,
                        group: work.optionGroup,
                        messages: work.message,
                        photos: work.photo,
                        zooms: work.zoom,
                        owner: work.ownerName,
                        start: work.start_text,
                        finish: work.finish_text
                    }
                    )
                }

                // console.log(work);

            } else {
                // console.log(data);
            }
      
            // this.setState({ data: this.mapToViewModel(movie) });
          } catch (ex) {
            if (ex.response && ex.response.status === 404)
              this.props.history.replace("/not-found");
          }
    }


    setupSocketIO = () => {
        socketIOClient.on('update-work-content', async message => {
            // console.log("Notify", "update-work-content", message);
            if ( this.state.id === message) {
                await this.loadWork()
            }
          })
      }

    render() { 
        // const { name, start, finish, owner, status } = this.state;
        // const { messages, photos, zooms } = this.state;
        const id = this.props.match.params.id;

        return ( 
            <React.Fragment>
                <EventCard id={id} />
            </React.Fragment>
         );
    }
}
 
export default Work;