import React, { Component } from "react";

import SearchBox from "../common/searchBox";
// import { toast } from "react-toastify";
// import ListGroup from '../common/listGroup';
import ConfirmDelete from './../common/confirmDelete';
import { toast } from "react-toastify";
import BadgeButton from "../common/badgeButton";
import { getSites, getBusinesses, getDepartments, deleteByTag, getDivisions } from './../../services/areaService';
import CreateSiteForm from '../site/formSiteCreate';
import CreateBusinessForm from '../business/formBusinessCreate';
import CreateDepartmentForm from './../department/formDepartmentCreate';
import EditDepartmentForm from './../department/formDepartmentEdit';
import EditSiteForm from './../site/formSiteEdit';
import EditBusinessForm from './../business/formBusinessEdit';
import EditDivisionForm from './../division/formDivisionEdit';
import CreateDivisionForm from './../division/formDivisionCreate';


class Branches extends Component {
  _isMounted = false;

  state = {
    sites: [],
    businesses: [],
    departments: [],
    divisions: [],
    searchSite: "",
    searchBusiness: "",
    searchDepartment: "",
    searchDivision: "",
    isModalDeleteOpen: false,
    askDelete: {},
    askEdit: {}
  };

  async componentDidMount() {
    this._isMounted = true;

    this.loadSites();
    this.loadBusinesses();
    this.loadDepartments();
    this.loadDivisions();
    
  }

  componentWillUnmount() { this._isMounted = false;  }

  async loadSites() {
    const { data } = await getSites();

    if ( data.status === 'success') {
      if ( this._isMounted) this.setState({ sites : data.data.site });
    }
  }

  async loadBusinesses() {
    const { data } = await getBusinesses();

    if ( data.status === 'success') {
      if ( this._isMounted) this.setState({ businesses : data.data.business });
    }
  }

  async loadDepartments() {
    const { data } = await getDepartments();

    if ( data.status === 'success') {
      if ( this._isMounted) this.setState({ departments : data.data.department });
    }
  }

  async loadDivisions() {
    const { data } = await getDivisions();

    if ( data.status === 'success') {
      if ( this._isMounted) this.setState({ divisions : data.data.division });
    }
  }

  getFilterSites = () => {
    const { sites, searchSite } = this.state;

    return (searchSite) ? sites.filter(item => 
        item.name && item.name.toLowerCase().includes(searchSite.toLowerCase())
        ) : sites;
  };

  getFilterBusinesses = () => {
    const { businesses, searchBusiness } = this.state;

    return (searchBusiness) ? businesses.filter(item => 
        item.name && item.name.toLowerCase().includes(searchBusiness.toLowerCase())
        ) : businesses;
  };

  getFilterDepartments = () => {
    const { departments, searchDepartment } = this.state;

    return (searchDepartment) ? departments.filter(item => 
        item.name && item.name.toLowerCase().includes(searchDepartment.toLowerCase())
        ) : departments;
  };

  getFilterDivisions = () => {
    const { divisions, searchDivision } = this.state;

    return (searchDivision) ? divisions.filter(item => 
        item.name && item.name.toLowerCase().includes(searchDivision.toLowerCase())
        ) : divisions;
  };

  
  handleSearchSite = query => {
    this.setState({ searchSite: query });
  };

  handleSearchBusiness = query => {
    this.setState({ searchBusiness: query });
  };

  handleSearchDepartment = query => {
    this.setState({ searchDepartment: query });
  };

  handleSearchDivision = query => {
    this.setState({ searchDivision: query });
  };

  notifyUpdateSite = () => {
    this.setState({ askEdit: {}})
    this.loadSites()
  }

  notifyUpdateBusiness = () => {
    this.setState({ askEdit: {}})
    this.loadBusinesses()
  }

  notifyUpdateDepartment = () => {
    this.setState({ askEdit: {}})
    this.loadDepartments()
  }

  notifyUpdateDivision = () => {
    this.setState({ askEdit: {}})
    this.loadDivisions()
  }

  askEdit = ({_id: id}) => {
    this.setState({askEdit: { id: id}})
  }

  onCancelEdit = () => {
    this.setState({askEdit: {}})
  }

  askDelete = (item, tag) => {
    console.log(tag, item)
    this.setState({isModalDeleteOpen: true, askDelete: { tag: tag, item: item }})
  }

  onDeleteCancel = () => {
    this.setState({isModalDeleteOpen: false, askDelete: {}})
  }

  onDeleteConfirm = async () => {
    this.setState({isModalDeleteOpen: false})

    const { askDelete } = this.state;
    const { tag, item} =askDelete;
    if ( tag && item) {
      try {
        await deleteByTag(tag, item);

        switch(tag) {
          case "site" : this.notifyUpdateSite(); break;
          case "business" : this.notifyUpdateBusiness(); break;
          case "department" : this.notifyUpdateDepartment(); break;
          case "division" : this.notifyUpdateDivision(); break;
          default : break;
        }

        this.setState({ askDelete: {}})
      } catch(err) {
        toast.error("ผิดพลาด " + err.message)
      }
    } else {
      toast.error("ไม่พบข้อมูลที่ต้องการลบ")
    }
  }



  render() {
    const { searchSite, searchBusiness, searchDepartment, searchDivision, isModalDeleteOpen, askEdit } = this.state;

    const sites = this.getFilterSites();
    const businesses = this.getFilterBusinesses();
    const departments = this.getFilterDepartments();
    const divisions = this.getFilterDivisions();

    return (
      <React.Fragment>
        <ConfirmDelete isOpen={isModalDeleteOpen} onConfirm={this.onDeleteConfirm} onCancel={this.onDeleteCancel} />
        <div className="row mb-3">
          <div className="col-auto mr-auto">
            <h5>Site / พื้นที่</h5>
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <SearchBox value={searchSite} onChange={this.handleSearchSite} />
          </div>
        </div>
        <div className="mb-3">
          <CreateSiteForm notifyUpdate={this.notifyUpdateSite} />
        </div>
        <div className="mb-4">
          {sites && sites.map(item => {
            if ( item.status || true ) {
              if ( askEdit && item._id===askEdit.id) {
                return(
                  <div key={item._id}  className="mb-4">
                    <EditSiteForm data={item} notifyUpdate={this.notifyUpdateSite} cancalEdit={this.onCancelEdit}  />
                  </div>
                )
              } else {
                return (
                <BadgeButton key={item._id} id={item._id} item={item} tag="site" itemText={item.name} buttonText="ลบ"
                onItemClick={this.askEdit} onButtonClick={this.askDelete} disabled={false} /> )
              }
            } else {
              return (<BadgeButton key={item._id} id={item._id} item={item} itemText={item.name} buttonText="เปิด" buttonClass="btn btn-outline-dark"
              onItemClick={this.askEdit} onButtonClick={this.askEnable} disabled={true} /> )
            }
            
          })}
        </div>
        <div style={{height: "30px"}}></div>
        <div className="row mt-4 mb-3">
          <div className="col-auto mr-auto">
            <h5>Business / สายงาน</h5>
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <SearchBox value={searchBusiness} onChange={this.handleSearchBusiness} />
          </div>
        </div>
        <div className="mb-3">
          <CreateBusinessForm notifyUpdate={this.notifyUpdateBusiness} />
        </div>
        <div className="mb-4">
          {businesses && businesses.map(item => {
            if ( item.status || true ) {
              if ( askEdit && item._id===askEdit.id) {
                return(
                  <div key={item._id}  className="mb-4">
                    <EditBusinessForm data={item} notifyUpdate={this.notifyUpdateBusiness} cancalEdit={this.onCancelEdit}  />
                  </div>
                )
              } else {
                return (
                <BadgeButton key={item._id} id={item._id} item={item} tag="business" itemText={item.name} buttonText="ลบ"
                onItemClick={this.askEdit} onButtonClick={this.askDelete} disabled={false} /> )
              }
            } else {
              return (<BadgeButton key={item._id} id={item._id} item={item} itemText={item.name} buttonText="เปิด" buttonClass="btn btn-outline-dark"
              onItemClick={this.askEdit} onButtonClick={this.askEnable} disabled={true} /> )
            }
            
          })}
        </div>
        <div style={{height: "30px"}}></div>
        <div className="row mt-4 mb-3">
          <div className="col-auto mr-auto">
            <h5>Department / หน่วยงาน</h5>
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <SearchBox value={searchDepartment} onChange={this.handleSearchDepartment} />
          </div>
        </div>
        <div className="mb-3">
          <CreateDepartmentForm notifyUpdate={this.notifyUpdateDepartment} />
        </div>
        <div className="mb-4">
          {departments && departments.map(item => {
            if ( item.status || true ) {
              if ( askEdit && item._id===askEdit.id) {
                return(
                  <div key={item._id}  className="mb-4">
                    <EditDepartmentForm data={item} notifyUpdate={this.notifyUpdateDepartment} cancalEdit={this.onCancelEdit}  />
                  </div>
                )
              } else {
                return (
                <BadgeButton key={item._id} id={item._id} item={item} tag="department" itemText={item.name} buttonText="ลบ"
                onItemClick={this.askEdit} onButtonClick={this.askDelete} disabled={false} /> )
              }
            } else {
              return (<BadgeButton key={item._id} id={item._id} item={item} itemText={item.name} buttonText="เปิด" buttonClass="btn btn-outline-dark"
              onItemClick={this.askEdit} onButtonClick={this.askEnable} disabled={true} /> )
            }
            
          })}
        </div>
        <div style={{height: "30px"}}></div>
        <div className="row mt-4 mb-3">
          <div className="col-auto mr-auto">
            <h5>Division / แผนก</h5>
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <SearchBox value={searchDivision} onChange={this.handleSearchDivision} />
          </div>
        </div>
        <div className="mb-3">
          <CreateDivisionForm notifyUpdate={this.notifyUpdateDivision} />
        </div>
        <div className="mb-4">
          {divisions && divisions.map(item => {
            if ( item.status || true ) {
              if ( askEdit && item._id===askEdit.id) {
                return(
                  <div key={item._id}  className="mb-4">
                    <EditDivisionForm data={item} notifyUpdate={this.notifyUpdateDivision} cancalEdit={this.onCancelEdit}  />
                  </div>
                )
              } else {
                return (
                <BadgeButton key={item._id} id={item._id} item={item} tag="division" itemText={item.name} buttonText="ลบ"
                onItemClick={this.askEdit} onButtonClick={this.askDelete} disabled={false} /> )
              }
            } else {
              return (<BadgeButton key={item._id} id={item._id} item={item} itemText={item.name} buttonText="เปิด" buttonClass="btn btn-outline-dark"
              onItemClick={this.askEdit} onButtonClick={this.askEnable} disabled={true} /> )
            }
            
          })}
        </div>
      </React.Fragment>
      
    );
  }
}

export default Branches;
