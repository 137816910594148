import React from "react";

const SelectButton = ({ name, label, value, options, onSelect, error, textProperty, valueProperty, ...rest }) => {

  // console.log(options);
  // console.log(value);

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <ul className="list-group list-group-horizontal">
        {options && options.map(item => {
          return (<li
            key={item[valueProperty]}
            onClick={() => onSelect({name: name, item: item})}
            className={ value && value.filter(x => x.value===item[valueProperty]).length>0 ? "list-group-item active py-2" : "list-group-item py-2" }
          >
            {item[textProperty]}
          </li>)
        })}
      </ul>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

SelectButton.defaultProps = {
  textProperty: "name",
  valueProperty: "_id"
};

export default SelectButton;
