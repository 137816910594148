import http from "./httpService";

const apiEndpoint = "/report";

export function getReportOverview(start='', end='') {
  return http.get(apiEndpoint + "/overview/?start=" + start + "&end=" + end);
}

export function downloadWorkReport(id) {
  return http.get(apiEndpoint + '/download/work/' + id, {responseType: 'blob'})
}