import React, {Component} from "react";
import { Link, NavLink } from "react-router-dom";
import ConfirmLogout from './../common/confirmLogout';
import Clock from 'react-live-clock';

class NavBar extends Component {
  state = { 
    collapsed: true,
    isModalLogoutOpen: false,
   }

   toggleNavbar =() => {
    const { onSidebarToggle } = this.props;

    if( typeof onSidebarToggle ==='function') {
      onSidebarToggle();
    }
  }


  hide = () => this.setState({ collapsed: true});

  doLogout = async () => {
    this.setState({isModalLogoutOpen: true, collapsed: true})
  }

  onLogoutCancel = () => {
    this.setState({isModalLogoutOpen: false})
  }

  onLogoutConfirm = async () => {
    this.setState({isModalDeleteOpen: false})

    this.props.history.replace("/logout");
  }

  render() { 
    const { user} = this.props;

    const { isModalLogoutOpen}  = this.state;

    return ( 
      <React.Fragment>
        <ConfirmLogout isOpen={isModalLogoutOpen} onConfirm={this.onLogoutConfirm} onCancel={this.onLogoutCancel} />
        <nav className="navbar navbar-dark fixed-top flex-md-nowrap p-0 shadow">
          <button
              className="navbar-toggler d-inline d-lg-none"
              type="button"
              onClick={this.toggleNavbar}
            >
              <span className="navbar-toggler-icon" />
            </button>
          <Link className="navbar-brand ml-2" to="/">We Join</Link>
          {user && <div>
            <strong className="d-none d-xl-inline ml-2"><Clock format="ddd" ticking={true} interval={60000} /></strong>
            <strong className="d-none d-md-inline ml-2">
              <Clock format="D MMMM YYYY" ticking={true} interval={60000} />
            </strong>
            <strong className="d-none d-md-inline ml-2"><Clock format="HH:mm:ss" ticking={true} interval={1000} /></strong>
          </div>}
          
          {user && <span className="d-none d-sm-inline ml-auto text-white">Login : {user.name}</span>}
          {user && <ul className="d-none d-sm-inline navbar-nav col-auto px-3">
            <li className="nav-item text-nowrap">
              <Link className="nav-item nav-link" to="/logout" onClick={this.hide}>ออกจากระบบ</Link>
            </li>
          </ul>}
          {!user && (
                <React.Fragment>
                  <NavLink className="nav-item nav-link text-white" to="/login" onClick={this.hide}>Login</NavLink>
                </React.Fragment>
              )}
        </nav>
      </React.Fragment>
      
     );
  }
}

export default NavBar;
