import React, { Component } from "react";
import { getHistory } from '../../services/workService';

import SearchBox from "../common/searchBox";
import { toast } from "react-toastify";
import _ from "lodash";
// import socketio from 'socket.io-client';
import socketIOClient from './../../services/socketioService';
import LiveCardTable from './liveCardTable';
import { getReportOverview } from "../../services/reportService";
import ReportOverview from "./reportOverview";
import ReportActivityGroup from './reportActivityGroup';
import ReportAudienceGroup from "./reportAudienceGroup";
import ReportCreator from "./reportCreator";

import moment from 'moment';
import Flatpickr from "react-flatpickr";
import ButtonGroup from './../common/buttonGroup';
import FontAwesome from 'react-fontawesome';

moment.updateLocale('th', {week: {
    dow: 1,
  },
})

class Live extends Component {
  _isMounted = false;

  filterDates = [{name: 'ประจำวัน', value: 'day'}, {name: 'สัปดาห์', value: 'week'}, {name: 'เดือน', value: 'month'}];
  
  state = {
    data: [],
    selectFilterDate: {},
    filterDateStart: moment().format('YYYY-MM-DD'),
    searchQuery: ""
  };

  async componentDidMount() {
    this._isMounted = true;

    await this.setState({ selectFilterDate: this.filterDates.find(x=>x.value==='day')})
    // await this.loadBranches()
    this.loadReportOverview();
    this.loadHistory()

    this.setupNotifyLive()
  }

  componentWillUnmount() {
    this._isMounted = false;
    socketIOClient.off('update-work-live');
  }

  setupNotifyLive = () => {
    socketIOClient.on('update-work-live', async () => {
      // console.log("Notify Update Live");
      if( this.state.selectFilterDate.value ===' day') {
        await this.loadHistory()
      }
    })
  }

  async loadReportOverview() {

    const { selectFilterDate, filterDateStart } = this.state;
    const start = filterDateStart;
    const end = (selectFilterDate.value==='day') ? "" : 
      (selectFilterDate.value==='week') ? moment(filterDateStart, "YYYY-MM-DD").endOf('week').format("YYYY-MM-DD") : 
      (selectFilterDate.value==='month') ? moment(filterDateStart, "YYYY-MM-DD").endOf('month').format("YYYY-MM-DD") : "";

    const { data: result } = await getReportOverview(start, end);
    if ( result.status === 'success') {
      if ( this._isMounted) this.setState({ reportOverview: result.data.report });
    } else {
        toast.error("Error : " + result.message);
    }
  }

  async loadHistory() {
    const { selectFilterDate, filterDateStart } = this.state;

    if(selectFilterDate.value==='day') {
      const filterDate = filterDateStart
      const tag=''
  
      const { data: result } = await getHistory(filterDate, tag);
  
      if ( result.status === 'success') {
        if(this._isMounted) this.setState({ data: result.data.works });
      } else {
        toast.error("Error : " + result.message);
      }
    }
    
  }

  getPagedData = () => {
    const { data, searchQuery } = this.state;

    const searched = data.filter(item => {
      if (item.area && item.area.toLowerCase().includes(searchQuery.toLowerCase())) return true;
      if (item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())) return true;
      if (item.tag && item.tag.toLowerCase().includes(searchQuery.toLowerCase())) return true;
      if (item.owner && item.owner.toLowerCase().includes(searchQuery.toLowerCase())) return true;
      if (item.desc && item.desc.toLowerCase().includes(searchQuery.toLowerCase())) return true;

      return false;
    });

    const sorted = _.orderBy(searched, "start", 'desc');

    return { totalCount: sorted.length, data: sorted };
  };

  handleFilterDate = async item => {

    let filterDateStart = this.state.filterDateStart;
    if(item.value==='week') {
      filterDateStart = moment().startOf('week').format("YYYY-MM-DD");
    } else if(item.value==='month') {
      filterDateStart = moment().startOf('month').format("YYYY-MM-DD");
    } else if(item.value==='day') {
      filterDateStart = moment().startOf('day').format("YYYY-MM-DD");
    }
    
    await this.setState({ selectFilterDate: item, searchQuery: "", filterDateStart: filterDateStart})

    this.loadReportOverview()
    this.loadHistory()
  }

  handleSearch = query => {
    this.setState({ selectedType: null, searchQuery: query, currentPage: 1 });
  };



  handleFilterDateStartChange = dateStart => {

    this.setState({ filterDateStart: dateStart })

    this.loadHistory();
    
  };

  handleFilterDateStartNext = async () => {

    const newFilter = moment(this.state.filterDateStart, "YYYY-MM-DD").add(1, 'day').format("YYYY-MM-DD");
    await this.setState({ filterDateStart: newFilter})

    this.loadReportOverview()
    this.loadHistory()

  }

  handleFilterDateStartPrevious = async () => {
    const newFilter = moment(this.state.filterDateStart, "YYYY-MM-DD").add(-1, 'day').format("YYYY-MM-DD");
    await this.setState({ filterDateStart: newFilter})

    this.loadReportOverview()
    this.loadHistory()
  }

  handleFilterDateWeekNext = async () => {

    const newFilter = moment(this.state.filterDateStart, "YYYY-MM-DD").add(1, 'week').format("YYYY-MM-DD");
    await this.setState({ filterDateStart: newFilter})

    this.loadReportOverview()

  }

  handleFilterDateWeekPrevious = async () => {
    const newFilter = moment(this.state.filterDateStart, "YYYY-MM-DD").add(-1, 'week').format("YYYY-MM-DD");
    await this.setState({ filterDateStart: newFilter})

    this.loadReportOverview()
  }

  handleFilterDateMonthNext = async () => {

    const newFilter = moment(this.state.filterDateStart, "YYYY-MM-DD").add(1, 'month').format("YYYY-MM-DD");
    await this.setState({ filterDateStart: newFilter})

    this.loadReportOverview()

  }

  handleFilterDateMonthPrevious = async () => {
    const newFilter = moment(this.state.filterDateStart, "YYYY-MM-DD").add(-1, 'month').format("YYYY-MM-DD");
    await this.setState({ filterDateStart: newFilter})

    this.loadReportOverview()
  }


  render() {
    // const { length: count } = this.state.movies;
    const { searchQuery, selectFilterDate, reportOverview, filterDateStart } = this.state;
    // const { user } = this.props;

    const { data } = this.getPagedData();
    const calendarOptions = { altInput: true, altFormat: "j F Y",  dateFormat: "Y-m-d"}

    return (
      <React.Fragment>
        <div className="row justify-content-end">
          <div className="col-auto mr-auto mb-3">
          <h4>รายงาน</h4>
          </div>
          <div className="col-auto mb-3">
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </div>
          <div className="col-auto mb-3 px-0">
            {this.filterDates &&(<ButtonGroup
              items={this.filterDates}
              selectedItem={this.state.selectFilterDate}
              onItemSelect={this.handleFilterDate}
              valueProperty='value'
            />)}
          </div>
          {selectFilterDate.value==='day' && 
          <div className="col-auto mb-3 ml-3">
            <div className="row align-items-center">
              <FontAwesome name="chevron-circle-left" size="2x" onClick={this.handleFilterDateStartPrevious} />
              <div className="d-inline-block mx-2">
              <Flatpickr
                  id='filterDateStart'
                  name='filterDateStart'
                  value={filterDateStart}
                  onChange={(_,str) => this.handleFilterDateStartChange(str)}
                  options={calendarOptions}
                />
              </div>
              
              <FontAwesome name="chevron-circle-right" size="2x" onClick={this.handleFilterDateStartNext} />
            </div>
          </div>}
          {selectFilterDate.value==='week' && 
          <div className="col-auto mb-3 ml-3">
            <div className="row align-items-center">
              <FontAwesome name="chevron-circle-left" size="2x" onClick={this.handleFilterDateWeekPrevious} />
              <div className="d-inline-block mx-3">
                <div className="alert alert-secondary py-2 d-inline">
                {moment(filterDateStart, "YYYY-MM-DD").format("DD MMMM YYYY")} - {moment(filterDateStart, "YYYY-MM-DD").endOf('week').format("DD MMMM YYYY")}
                </div>
              </div>
              <FontAwesome name="chevron-circle-right" size="2x" onClick={this.handleFilterDateWeekNext} />
            </div>
          </div>}
          {selectFilterDate.value==='month' && 
          <div className="col-auto mb-3 ml-3">
            <div className="row align-items-center">
              <FontAwesome name="chevron-circle-left" size="2x" onClick={this.handleFilterDateMonthPrevious} />
              <div className="d-inline-block mx-3">
                <div className="alert alert-secondary py-2 d-inline">
                  {moment(filterDateStart, "YYYY-MM-DD").format("MMMM YYYY")}
                </div>
              </div>
              <FontAwesome name="chevron-circle-right" size="2x" onClick={this.handleFilterDateMonthNext} />
            </div>
          </div>}
        </div>
        <div className="mt-2"><ReportOverview data={reportOverview} /></div>
        <ReportActivityGroup data={reportOverview} />
        <ReportAudienceGroup data={reportOverview} />
        <ReportCreator data={reportOverview} />
        {selectFilterDate.value==='day' && <div className="mt-4"><LiveCardTable data={data}  /></div>}
      </React.Fragment>
      
    );
  }
}

export default Live;
