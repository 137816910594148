import http from "./httpService";

const apiEndpoint = "/plan";

export function getPlans(planMode) {
  return http.get(apiEndpoint + "/?status=web&mode=" + planMode);
}

export function getPlan(id) {
  return http.get(apiEndpoint + "/" + id);
}

export function savePlan(doc) {
  if (doc._id) {
    //Update
    const body = { ...doc };
    delete body._id;
    return http.put(apiEndpoint + "/" + doc._id, body);
  } else {
    //Create
    return http.post(apiEndpoint, doc);
  }
}

export function deletePlan(id) {
  return http.delete(apiEndpoint + "/" + id);
}