import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import moment from 'moment';
import socketIOClient from '../../services/socketioService';
import { getWork } from '../../services/workService';

class LiveCard extends Component {
    _isMounted = false;
    workTimeInterval = null;

    state = { 
        data: {} 
    }

    componentDidMount() {
        this._isMounted = true;

        const data = this.props.item;
        const {  start, finish } = data;
        data.workTime = (finish) ? this.getWorkHour(start, finish) : this.getWorkHour(start, Date.now());

        this.setState({ data });
        this.setupSocketIO();

        if ( data.status==='active' && data.type!=='emergency') {
            this.workTimeInterval = setInterval( this.updateWorkTime, 60000);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.workTimeInterval);
        socketIOClient.off('update-work-content');
      }
    
      setupSocketIO = () => {
        socketIOClient.on('update-work-content', async message => {
            // console.log("Notify", "update-work-content", message);
            if ( this.state.data._id === message) {
                await this.loadWork()
            }
          })
      }

      updateWorkTime = () => {
          const data = {...this.state.data};
          
        data.workTime = this.getWorkHour(data.start, Date.now());
        this.setState({data});
      }


      async loadWork() {
        try {
            const {_id} = this.state.data;
            if ( _id) {
                const { data } = await getWork(_id);

                if ( data.status && data.status==='success') {
                    const { work } = data.data;
    
                    if ( this._isMounted) {

                        const data = {...this.state.data};
                        data.messageCount = work.message.length
                        data.photoCount = work.photo.length
                        data.zoomCount = work.zoom.length
                        data.update_text = work.update_text;
                        data.finish = work.finish;
                        data.finish_text = work.finish_text
                        data.status= work.status

                        if ( work.status === 'finish') clearInterval(this.workTimeInterval);

                        if ( work.zoom.filter(x=>x.isLive).length>0) {
                            data.isLive = true;
                            data.joinUrl = work.zoom.filter(x=>x.isLive).find(x=>x).joinUrl;
                        }
    
                        this.setState({ data })
                    }
    
                } else {
                    // console.log(data);
                }
            }
          } catch (ex) {
            if (ex.response && ex.response.status === 404)
              this.props.history.replace("/not-found");
          }
    }

    getWorkHour(start, end) {

        const diff = moment(end).diff(start, 'minutes');
 
        const days = Math.floor(diff/60/24);
        const day_text = (days>0) ? (days + ((days>1) ? ' วัน': ' วัน')) : '';
        
        const hours = Math.floor((diff%1440)/60);
        const hour_text = (hours>0) ? (hours + ((hours>1) ? ' ชั่วโมง': ' ชั่วโมง')) : '';
        
       const mins = Math.floor(diff%60);
       const min_text = (mins>0) ? (mins + ((mins>1) ? ' นาที': ' นาที')) : '';
 
        return (((day_text) ? day_text + ' ' : '') + ((hour_text) ? hour_text + ' ' : '') + ((min_text) ? min_text : '')).trimRight();
    }

    render() { 

        const { _id, name, type, desc, start_text, update_text, finish_text, owner, area, isLive, joinUrl, autoClose } = this.state.data;
        const { messageCount, photoCount, zoomCount, audienceCount } = this.state.data;
        const { status, finish, workTime } = this.state.data;

        if (!_id) return (<div></div>);

        const classMessage = (messageCount>0) ? 'col-auto text-center' : 'col-auto text-center text-muted'
        const classPhoto = (photoCount>0) ? 'col-auto text-center' : 'col-auto text-center text-muted'
        const classZoom = (zoomCount>0) ? 'col-auto text-center' : 'col-auto text-center text-muted'
        const classAudience = (audienceCount>0) ? 'col-auto text-center' : 'col-auto text-center text-muted'

        const classHeader = (type==='emergency') ? 'card-header bg-danger text-white py-2' 
                : (status==='active') ? 'card-header bg-warning py-2' 
                : (status==='finish') ? 'card-header bg-success text-white py-2'
                : 'card-header bg-secondary py-2';
        const classLive = (type==='emergency') ? 'badge badge-light':'badge badge-danger'

        return ( 
                <div className="card mb-3">
                    <div className={classHeader}>
                        <div className="row align-items-center justify-content-between">
                            <div className="col-auto">
                                <div className="row align-items-center">
                                    {area && <div className="col-auto px-2"><h5 className="d-inline"><span className="badge badge-light">{area}</span></h5></div>}
                                    {owner && <div className="col-auto px-2"><h5 className="d-inline"><FontAwesome name="user" /> {owner}</h5></div>}
                                </div>
                            </div>
                            <div className="col-auto">
                                <div><FontAwesome name="clock-o" /> {workTime}</div>
                                {isLive ? <a href={joinUrl} target="_blank" rel="noopener noreferrer">
                                    <span className={classLive}>Live</span>
                                    </a> : ""}
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <h5 className="card-title">
                                <Link className="stretched-link" to={`/work/${_id}`}>{name}</Link>
                                </h5>
                                <span className="card-text">{desc.split('\n').map(item => { return ( <span key={item}>{item}<br /></span>)})}</span>
                            </div>
                            <div className={classMessage}>
                                <div className="notify-item">
                                    {messageCount>0 && <span className="notify-badge">{messageCount}</span>}
                                    <FontAwesome name="commenting-o" size="2x" />
                                </div>
                            </div>
                            <div className={classPhoto}>
                                <div className="notify-item">
                                    {photoCount>0 && <span className="notify-badge">{photoCount}</span>}
                                    <FontAwesome name="picture-o" size="2x" />
                                </div>
                            </div>
                            <div className={classZoom}>
                                <div className="notify-item">
                                    {zoomCount>0 && <span className="notify-badge">{zoomCount}</span>}
                                    <FontAwesome name="video-camera" size="2x" />
                                </div>
                            </div>
                            <div className={classAudience}>
                                <div className="notify-item">
                                    {audienceCount>0 && <span className="notify-badge">{audienceCount}</span>}
                                    <FontAwesome name="users" size="2x" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-right text-muted small">
                        <div className="row justify-content-end col-auto px-0">
                        <div><FontAwesome name="clock-o" /> เริ่มต้น {start_text}</div>
                        {(!finish && start_text!==update_text) && <div className="ml-3"><FontAwesome name="clock-o" /> ล่าสุด {update_text}</div>}
                        {(finish && finish_text) && <div className="ml-3"><FontAwesome name="clock-o" /> เสร็จสิ้น {finish_text} {autoClose && <span className="badge badge-warning">AUTO-CLOSE</span>}</div>}
                        
                        </div>
                        
                    </div>
                </div>
         );
    }
}
 
export default LiveCard;