import React, {Component} from "react";
import { NavLink } from "react-router-dom";
import FontAwesome from 'react-fontawesome';

class SideNavBar extends Component {
  state = { 
    collapsed: true,
    isModalLogoutOpen: false,
   }

  toggleNavbar =() => this.setState({ collapsed: !this.state.collapsed });
  hide = () => this.setState({ collapsed: true});

  doLogout = async () => {
    this.setState({isModalLogoutOpen: true, collapsed: true})
  }

  onLogoutCancel = () => {
    this.setState({isModalLogoutOpen: false})
  }

  onLogoutConfirm = async () => {
    this.setState({isModalDeleteOpen: false})

    this.props.history.replace("/logout");
  }

  render() { 
    // const { user} = this.props;

    return ( 
      <React.Fragment>
        <div className="sidebar sidebar-sticky bg-dark text-white">
          <ul className="nav flex-column">
            <NavLink className="nav-item nav-link" to="/live" onClick={this.hide}>
              <FontAwesome name="bar-chart" /> รายงาน
            </NavLink>
            <NavLink className="nav-item nav-link" to="/history" onClick={this.hide}>
            <FontAwesome name="thumbs-up" /> กิจกรรม</NavLink>
            
          </ul>

          <h3 className="sidebar-heading px-3 mt-4 mb-1">
            จัดการตัวเลือก
          </h3>
          <ul className="nav flex-column mb-2 pl-3">
            <NavLink className="nav-item nav-link" to="/option/manage/5dde38abbd260b9e0493a7d2" onClick={this.hide}><FontAwesome name="wrench" /> พื้นที่</NavLink>
            <NavLink className="nav-item nav-link" to="/branch" onClick={this.hide}><FontAwesome name="wrench" /> พื้นที่ (เก่า)</NavLink>
            <NavLink className="nav-item nav-link" to="/template" onClick={this.hide}><FontAwesome name="wrench" /> กิจกรรม</NavLink>
          </ul>
          <NavLink className="nav-item nav-link mt-4" to="/staff" onClick={this.hide}>
              <FontAwesome name="users" /> ทีมงาน
            </NavLink>
        </div>
      </React.Fragment>
      
     );
  }
}

export default SideNavBar;
