import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import DefaultUser from './../../assets/default-user.jpg';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { enableStaff, disableStaff, deleteStaff } from '../../services/staffService';
import ConfirmDelete from './../common/confirmDelete';

class StaffCard extends Component {
  _isMounted = false;

    state = { 
      data: {},
      isModalDeleteOpen: false,
      isNew: false
    }

  componentDidMount() {
    this._isMounted = true;

    const data = this.props.item;
    this.setState({ data });
  }

  componentWillUnmount() { this._isMounted = false; }

    handleEnable = async () => {
      
      const { _id } = this.state.data;
      if ( _id ) {
        const backup= {...this.state.data}
        const data = {...this.state.data}
        data.status = true
        await this.setState({ data })

          try {
              const { data: result } = await enableStaff(_id);
              if ( result.status==='success') {

              } else {
                this.setState({ data: backup })
                toast.error(result.message);
              }
          } catch(err) {
            this.setState({ data: backup })
            toast.error("ผิดพลาด " + err.message)
          }
      } else {
          toast.error("ไม่พบข้อมูล staff id")
      }
  }

  handleDisable = async () => {
      
    const { _id } = this.state.data;
    if ( _id ) {
      const backup= {...this.state.data}
      const data = {...this.state.data}
      data.status = false
      await this.setState({ data })

        try {
            const { data: result } = await disableStaff(_id);
            if ( result.status==='success') {

            } else {
              this.setState({ data: backup })
              toast.error(result.message);
            }
        } catch(err) {
          this.setState({ data: backup })
          toast.error("ผิดพลาด " + err.message)
        }
    } else {
        toast.error("ไม่พบข้อมูล staff id")
    }
}

    askDeleteArea = () => {
      this.setState({isModalDeleteOpen: true })
    }
  
    onDeleteCancel = () => {
      this.setState({isModalDeleteOpen: false })
    }
  
    onDeleteConfirm = async () => {
      this.setState({isModalDeleteOpen: false})
  
      const { _id } = this.state.data;
  
      if ( _id ) {
          try {
              await deleteStaff(_id);
  
              const { notifyUpdate } = this.props
              if ( typeof notifyUpdate === 'function') {
                  notifyUpdate();
              }
  
          } catch(err) {
              toast.error("ผิดพลาด " + err.message)
          }
      } else {
          toast.error("ไม่พบข้อมูล staff id")
      }
    }

    render() { 
      const { item, onClickAdd, isNew=false } = this.props;
      const { _id, name, sim, email, position, department, company, status  } = this.state.data;
      const { isModalDeleteOpen } = this.state

      const classButtonEnable = 'btn btn-primary btn-sm'
      const classButtonDisable = 'btn btn-outline-secondary btn-sm'
        
      return ( 
        <React.Fragment>
          <ConfirmDelete isOpen={isModalDeleteOpen} onConfirm={this.onDeleteConfirm} onCancel={this.onDeleteCancel} />
          <div className="card mb-3" style={{width: '300px'}}>
            {!isNew && <div className="text-right pt-2 pr-2">
              {status && <FontAwesome className="text-success" name="check-circle" size="2x" />}
              {!status && <FontAwesome className="text-danger" name="ban" size="2x" />}
            </div>}
            <div className="text-center mt-2">
              <img className="rounded-circle" style={{width: "200px"}} src={DefaultUser} alt="..." />
            </div>
            <div className="card-body">
              <h5 className="card-title"><FontAwesome name="user" size="lg" /> <Link className="text-info" to={`/template/${_id}`}>{name}</Link></h5>
              {position && <p className="card-text"><FontAwesome name="briefcase" /> {position}</p>}
              {department && <p className="card-text"><FontAwesome name="briefcase" /> {department}</p>}
              <p className="card-text"><FontAwesome name="building" /> {company}</p>
              {sim && <span className="card-text"><FontAwesome name="phone-square" /> {sim}<br /></span>}
              {email && <span className="card-text small"><FontAwesome name="envelope" /> {email}</span>}
              {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
            </div>
            <div className="card-footer text-right small p-2">
              {isNew && <button type="button" className="btn btn-primary btn-sm" onClick={() => onClickAdd(item)}>เพิ่ม</button>}
              {!isNew && <div className="btn-group ml-2">
                  <button type="button" className={(status) ? classButtonEnable : classButtonDisable} onClick={this.handleEnable}>เปิด</button>
                  <button type="button" className={(!status) ? classButtonEnable : classButtonDisable} onClick={this.handleDisable}>ปิด</button>
              </div>}
              {!isNew && <button type="button" className='btn btn-danger btn-sm text-white ml-2' onClick={this.askDeleteArea}>ลบ</button>}
            </div>
          </div>
        </React.Fragment>
        
        );
    }
}
 
export default StaffCard;