import React, { Component } from "react";
import StaffCard from './staffCard';

class StaffCardTable extends Component {

  render() {
    const { data, notifyUpdate } = this.props;

    return (
      <div className="row">
        {data && data.map( item => {
          return (
            <div key={item._id} className="col-auto">
              <StaffCard item={item} notifyUpdate={notifyUpdate} />
            </div>
          )
        })}
      </div>
    );
  }
}

export default StaffCardTable;
