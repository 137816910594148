import http from "./httpService";

const apiEndpoint = "/template";

export function getTemplateType() {
  // console.log("URL", apiEndpoint);
  return http.get(apiEndpoint + "/type");
}

export function getTemplateList() {
  return http.get(apiEndpoint);
}

export function getTemplate(id) {
  return http.get(apiEndpoint + "/" + id);
}

export function saveTemplate(template) {
  if (template._id) {
    const body = { ...template };
    delete body._id;
    return http.put(apiEndpoint + "/" + template._id, body);
  }

  return http.post(apiEndpoint, template);
}

export function enableTemplate(id) {
  return http.put(apiEndpoint + "/" + id + "/enable" );
}

export function disableTemplate(id) {
  return http.put(apiEndpoint + "/" + id + "/disable" );
}

export function deleteTemplate(id) {
  return http.delete(apiEndpoint + "/" + id);
}