import React from 'react';
import Chart from 'react-apexcharts'

const ReportCreator = ({data}) => {

  if(!data) return (<div></div>)

  const { creatorGroup } = data;

  const yLabels = creatorGroup.labels
  const xData = creatorGroup.data

      const options = {
        chart: {
          stacked: false,
          toolbar: { show: false }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            endingShape: 'flat',
            barHeight: '80%',
          },

        },
        xaxis: {
          categories: yLabels,
          lines: { show: false }
        },
        yaxis: {
          lines: { show: false }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        },
        fill: {
          opacity: 1

        },

  
      }

      const series = [{
        data: xData
      }]

      const charHeight = (xData.length * 50 + 50).toString();
  

    return (
      <div className="row">
        <div className="col-12">
            <div className="card">
                <div className="card-header text-center">
                    ผู้จัดกิจกรรม
                </div>
                <div className="card-body text-center">

                  {xData.length>0 && <Chart options={options} series={series} type="bar" height={charHeight} />}
                  {xData.length===0 && <span className="text-muted">ไม่มีข้อมูล</span>}
                </div>
            </div>
        </div> 
      </div>
    );
  


}
 
export default ReportCreator;