import http from "./httpService";
import jwtDecode from "jwt-decode";
import md5 from 'md5';

const apiEndpoint = "/session";
const tokenKey = "token";

http.setJwt(getJwt());

export async function login(account, password) {

  try {
    const data = { account: account, password: md5(password), role: 'web'};
    const { data: result } = await http.post(apiEndpoint + "/IDMSAuthen", data);
    
    if ( result.status === 'success') {

      const jwt = result.data.token;
      localStorage.setItem(tokenKey, jwt);
    
      return "OK";
    } else {
      return result.message;
    }
  } catch(err) {
    return err.message;
  }
  
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return undefined;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt
};
