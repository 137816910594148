import http from "./httpService";

const apiEndpoint = "/area";

export function getAreas() {
  return http.get(apiEndpoint);
}

export function getArea(id) {
  return http.get(apiEndpoint + "/" + id);
}

export function createArea(doc) {
  return http.post(apiEndpoint , doc )

}

export function updateArea(id , doc) {
  return http.put(apiEndpoint + "/" + id , doc )
}

export function disableArea(id) {
  return http.put(apiEndpoint + "/disable/" + id)
}

export function enableArea(id) {
  return http.put(apiEndpoint + "/enable/" + id)
}


export function getSites() {
  return http.get(apiEndpoint + "/site");
}

export function getBusinesses() {
  return http.get(apiEndpoint + "/business");
}

export function getDepartments() {
  return http.get(apiEndpoint + "/department");
}

export function getDivisions() {
  return http.get(apiEndpoint + "/division");
}

export function saveSite(doc) {
  if (doc._id) {
    //Update
    const body = { ...doc };
    delete body._id;
    return http.put(apiEndpoint + "/site/" + doc._id, body);
  } else {
    //Create
    return http.post(apiEndpoint + "/site", doc);
  }
}

export function saveBusiness(doc) {
  if (doc._id) {
    //Update
    const body = { ...doc };
    delete body._id;
    return http.put(apiEndpoint + "/business/" + doc._id, body);
  } else {
    //Create
    return http.post(apiEndpoint + "/business", doc);
  }
}

export function saveDepartment(doc) {
  if (doc._id) {
    //Update
    const body = { ...doc };
    delete body._id;
    return http.put(apiEndpoint + "/department/" + doc._id, body);
  } else {
    //Create
    return http.post(apiEndpoint + "/department", doc);
  }
}

export function saveDivision(doc) {
  if (doc._id) {
    //Update
    const body = { ...doc };
    delete body._id;
    return http.put(apiEndpoint + "/division/" + doc._id, body);
  } else {
    //Create
    return http.post(apiEndpoint + "/division", doc);
  }
}

export function deleteByTag(tag, item) {
  if(tag && item && item._id) {
    return http.delete(apiEndpoint + "/" + tag + "/" + item._id)
  }
}