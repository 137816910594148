import React from 'react';

const BadgeButton = ({id, item, tag, itemText, itemClass='btn btn-info', buttonText, buttonClass='btn btn-outline-secondary', onItemClick, onButtonClick, disabled=''}) => {
    return ( 
        <React.Fragment>
            <div key={id} className="btn-group mr-3 mb-3" role="group">
                {disabled && <button type="button" className={itemClass} disabled onClick={() => onItemClick(item)}>{itemText}</button>}
                {!disabled && <button type="button" className={itemClass} onClick={() => onItemClick(item)}>{itemText}</button>}
                <button type="button" className={buttonClass} onClick={() => onButtonClick(item,tag)}>{buttonText}</button>
            </div>
        </React.Fragment>
     );
}
 
export default BadgeButton;