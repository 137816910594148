import React, { Component } from "react";
import StaffCard from "./staffCard";

class SearchStaffTable extends Component {
  render() {
    const { data, handleClickAdd, isNew } = this.props;

    return (
      <React.Fragment>
        <div className="row">
          {data && data.map( item => {
            return (
              <div key={item.empId} className="col-auto">
                <StaffCard item={item} onClickAdd={handleClickAdd} isNew={isNew} />
              </div>
            )
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default SearchStaffTable;
