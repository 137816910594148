import React, { Component } from "react";

// import _ from "lodash";

import { Link } from "react-router-dom";
import SearchBox from "../common/searchBox";
import { toast } from "react-toastify";
import { getStaffs } from "../../services/staffService";
import { getBranches } from "../../services/branchService";
import ListGroup from './../common/listGroup';
import StaffCardTable from './staffCardTable';


class Staff extends Component {
  _isMounted = false;

  state = {
    staffs: [],
    branches: [],
    selectBranch: {id: 'all', name: "All"},
    pageSize: 10,
    currentPage: 1,
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" }
  };

  async componentDidMount() {
    this._isMounted = true;

    await this.loadBranches();
    await this.loadStaffs();
    
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async loadBranches() {
    const { data: result2 } = await getBranches()

    if ( result2.status==='success') {
      const branches = ( result2.data.branches) ? [...[{name: "All", value: "all"}],...result2.data.branches] : null;
      const selectBranch = (branches.length>0) ? branches[0] : null;
  
      if ( this._isMounted) this.setState( { branches: branches, selectBranch: selectBranch});
    }
  }

  async loadStaffs() {
    const { data: result } = await getStaffs();
    if ( result.status === 'success') {
      if ( this._isMounted) this.setState({ staffs: result.data.staffs });
    } else {
        toast.error("Error : " + result.message);
    }
  }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { staffs, selectBranch, searchQuery } = this.state;

    const filtered = 
      selectBranch && selectBranch.name && selectBranch.value!=='all'
        ? staffs.filter(item => item.branchValue.split(",").indexOf(selectBranch.value) > -1)
        : staffs;

    const searched = filtered.filter(item => {
      if (item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())) return true;
      if (item.empId && item.empId.includes(searchQuery)) return true;
      if (item.sim && item.sim.includes(searchQuery)) return true;

      return false;
    });

    return { data: searched };
  };

  handleSearch = query => {
    this.setState({ selectedType: null, searchQuery: query, currentPage: 1 });
  };

  handleBranchSelect = branch => {
    this.setState({ selectBranch: branch, searchQuery: "", currentPage: 1 });
  };

  notifyUpdate = () => { this.loadStaffs();  }

  render() {
    // const { length: count } = this.state.movies;
    const { searchQuery } = this.state;
    const { user } = this.props;

    const { data: staffs } = this.getPagedData();

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-auto mr-auto mb-3">
          <h4>Staffs</h4>
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-3">
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </div>
          <div className="col-auto mb-3">
            {user && (<Link to="/staff/new" className="btn btn-primary" style={{ marginBottom: 20 }} >New Staff</Link> )}
          </div>
        </div>
        <div className="row mb-3 d-none">
          <div className="col-md-4 align-self-center">
            
          </div>
          <div className="col-md-8 align-self-center">
          <div className="row justify-content-end pr-3">
              {this.state.branches &&(<ListGroup
                items={this.state.branches}
                selectedItem={this.state.selectBranch}
                onItemSelect={this.handleBranchSelect}
                valueProperty='value'
              />)}
            </div>
          </div>
        </div>
        <StaffCardTable data={staffs} notifyUpdate={this.notifyUpdate} />
      </React.Fragment>
      
    );
  }
}

export default Staff;
