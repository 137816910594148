import React, { Component} from "react";
// import Image from 'react-bootstrap/Image'
// import { loadPhoto } from "../../services/userService";

class Profile extends Component {
  state = {  }

  async componentDidMount() {
    // console.log(this.props);
    // const { user } = this.props;

    // console.log(user);

    // if ( user && user.empId) {
    //   const { data } = await loadPhoto(user.empId);
    //   console.log(data);
    // }

  }

  render() { 



    return ( 
    <React.Fragment>
      <h3>My Profile</h3>
      
    </React.Fragment> );
  }
}
 

export default Profile;
