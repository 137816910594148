import React, { Component } from 'react';

import BadgeButton from '../common/badgeButton';
import SearchBox from '../common/searchBox';

class StaffSelector extends Component {
    _isMounted = false;

    state = { 
        selected: [],
        items: [],
        search: "",
        branch: "",
        area: "",
        disabled: false
     }

     componentDidMount() {
        this._isMounted = true;
         this.loadProps();
     }

     componentWillUnmount() { this._isMounted = false; }

     loadProps() {
        const { items, branch, area, disabled } = this.props;
        let { selected } = this.props;

        if ( this._isMounted) this.setState({ items, branch, area, selected, disabled });
     }

     handleSearchStaff = search => {
        this.setState({search: search});
    }

    getItems = () => {
        const { items, search, selected, branch, area } = this.state;

        var filtered = items.filter(x=>x.branchValue===branch).filter(x=> x.areaValue===area).filter(x => selected.filter(y=>y.empId===x.empId).length===0);

        return (search)
        ? (filtered.filter(x => 
        x.empId.startsWith(search)
        || x.name.toLocaleUpperCase().includes(search.toLocaleUpperCase())
        || x.sim.includes(search)
        )) : filtered
    }


     doNotify = selected => {
        const { notifyChange } = this.props;

        if ( typeof notifyChange === 'function') {
            notifyChange({ items: selected });
        }
     }

     doAddItem = item => {
    
        const selected = [...this.state.selected];
        if ( selected.indexOf(item)<0) {
          selected.push(item);
    
          if ( this._isMounted)  this.setState({ selected });

          this.doNotify(selected);
        }
      }
    
      doRemoveItem = item => {
        const selected = [...this.state.selected];
        const index = selected.indexOf(item);
    
        if ( index > -1) {
          selected.splice(index,1);
    
          if ( this._isMounted)  this.setState({ selected });

          this.doNotify(selected);
        } 
      }

    render() { 
        const { header, emptyText } = this.props;
        const { search, selected, branch, area, disabled } = this.state;
        const items = this.getItems();
        const isEmpty = (this.state.items.filter(x=> x.branchValue===branch).filter(x=> x.areaValue===area).length===0) ? true : false;

        return ( 
            <React.Fragment>
                <div className="card mt-4">
                <div className="card-header">
                    {header}
                </div>
                <div className="container-fluid p-3">
                    {(!selected || selected.length===0) && 
                    <p className="text-center text-secondary mt-3">{emptyText}</p>
                    }
                    {selected && selected.length>0 && selected.map(item => {
                        if(disabled) {
                            return (<h5 key={item.empId}><span className="badge badge-info p-2">{item.name}</span></h5> )
                        } else {
                            return (
                                <BadgeButton key={item.empId} id={item.empId} item={item} itemText={item.name} buttonText="ลบ"
                                onItemClick={this.doRemoveItem} onButtonClick={this.doRemoveItem} disabled={false} /> )
                        }
                    
                    })}
                </div>
                </div>
                {!disabled && <div className="container-fluid p-3 mt-3">
                {isEmpty && 
                    <div className="row">
                    <div className="col"><div className="alert alert-warning">ไม่มีข้อมูลพนักงานสาขานี้</div></div>
                    </div>}
                {items && items.length>0 && <SearchBox value={search} onChange={this.handleSearchStaff} placeholder="ค้นหาพนักงาน" className="form-control mb-3 col-12 col-md-8" />}
                {items && items.map(item => {
                    return (
                    <BadgeButton key={item.empId} id={item.empId} item={item} itemText={item.name} buttonText="เพิ่ม" itemClass="btn btn-secondary"
                    onItemClick={this.doAddItem} onButtonClick={this.doAddItem} disabled={false} /> )
                })}
                </div>}
            </React.Fragment>
         );
    }
}
 
export default StaffSelector;