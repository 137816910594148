import React, { Component } from "react";
import { getHistory } from '../../services/workService';
import { getBranches } from '../../services/branchService';

// import Dropdown from './../common/dropdown';
import _ from "lodash";
import { toast } from "react-toastify";
import FontAwesome from 'react-fontawesome';

// import { Link } from "react-router-dom";
import SearchBox from "../common/searchBox";
import socketIOClient from '../../services/socketioService';
import moment from 'moment';
import Flatpickr from "react-flatpickr";
import LiveCardTable from './../live/liveCardTable';
import ButtonGroup from './../common/buttonGroup';


class History extends Component {
  _isMounted = false;

  state = {
    data: [],
    selectFilterDate: {},
    filterDateStart: moment().format('YYYY-MM-DD'),
    filterDateEnd: moment().format('YYYY-MM-DD'),
    searchQuery: "",
    selectedType: {_id: "all", name: "All Type"},
  };

  filterDates = [{name: 'ทั้งหมด', value: 'all'}, {name: 'วันนี้', value: 'today'}, {name: 'เลือกวันที่', value: 'date'}];
  listType = [{_id: "all", name: "All Type"},{_id:"activity", name: "กิจกรรม"},{_id: "training", name: "อบรม"} ];

  async componentDidMount() {
    this._isMounted = true

    await this.setState({ selectFilterDate: this.filterDates.find(x=>x.value==='today')})

    //this.loadBranches();
    this.loadHistory();

    this.setupNotify()
  }

  componentWillUnmount() {
    this._isMounted = false;

    socketIOClient.off('update-work-history');
}

setupNotify = () => {
  socketIOClient.on('update-work-history', async () => {
    const { selectFilterDate, filterDateStart, filterDateEnd} = this.state;
    // console.log("Notify Work History");
    await this.loadHistory(selectFilterDate, filterDateStart, filterDateEnd);
  })

}

  async loadBranches() {
    const { data: result2 } = await getBranches()

    if ( result2.status==='success') {
      const branches = ( result2.data.branches) ? [...[{name: "All", value: "all"}],...result2.data.branches] : null;
      const selectBranch = (branches.length>0) ? branches[0] : null;

      if(this._isMounted) this.setState( { branches: branches, selectBranch: selectBranch});
    }
  }

  async loadHistory() {
    const { selectFilterDate } = this.state;
    const filterDate = (selectFilterDate.value==='all') ? '' : 
      (selectFilterDate.value==='today') ? moment().format("YYYY-MM-DD") : 
      (selectFilterDate.value==='date') ? this.state.filterDateStart : "";

    const tag=''

    const { data: result } = await getHistory(filterDate, tag);

    if ( result.status === 'success') {
      if(this._isMounted) this.setState({ data: result.data.works });
    } else {
      toast.error("Error : " + result.message);
    }
  }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { data, searchQuery } = this.state;

    const searched = data.filter(item => {
      if (item.area && item.area.toLowerCase().includes(searchQuery.toLowerCase())) return true;
      if (item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())) return true;
      if (item.tag && item.tag.toLowerCase().includes(searchQuery.toLowerCase())) return true;
      if (item.owner && item.owner.toLowerCase().includes(searchQuery.toLowerCase())) return true;
      if (item.desc && item.desc.toLowerCase().includes(searchQuery.toLowerCase())) return true;

      return false;
    });

    const sorted = _.orderBy(searched, "start", 'desc');

    return { totalCount: sorted.length, data: sorted };
  };

  handleSearch = query => {
    this.setState({ selectedType: null, searchQuery: query, currentPage: 1 });
  };

  handleBranchSelect = branch => {
    // console.log("Branch Select", branch);

    this.setState({ selectBranch: branch, searchQuery: "", currentPage: 1 });
  };

  handleFilterDate = async item => {
    await this.setState({ selectFilterDate: item, searchQuery: ""})

    this.loadHistory()
  }

  handleFilterDateStartChange = dateStart => {

    this.setState({ filterDateStart: dateStart })

    this.loadHistory();
    
  };

  handleFilterDateEndChange = dateEnd => {
    const filter = this.filterDates.filter(x => x.value==='date')[0];

    this.setState({filterDateEnd: dateEnd, selectFilterDate: filter})
    this.loadHistory(filter, this.state.filterDateStart, dateEnd);

  };

  handleFilterDateStartNext = async () => {

    const newFilter = moment(this.state.filterDateStart, "YYYY-MM-DD").add(1, 'day').format("YYYY-MM-DD");
    await this.setState({ filterDateStart: newFilter})

    this.loadHistory()

  }

  handleFilterDateStartPrevious = async () => {
    const newFilter = moment(this.state.filterDateStart, "YYYY-MM-DD").add(-1, 'day').format("YYYY-MM-DD");
    await this.setState({ filterDateStart: newFilter})

    this.loadHistory()
  }

  dropdownOrder = select => {
    // console.log(select);

    this.setState({ currentPage: 1, sortColumn: { path: select._id, order: (select._id==='start') ? "desc" : "asc"}});

  }

  dropdownType = select => {
    // console.log(select);
    this.setState({ selectedType: select, searchQuery: "", currentPage: 1})
  }

  render() {
    // const { length: count } = this.state.movies;
    const { searchQuery, selectFilterDate } = this.state;

    // const findOrder = this.listOrder.filter(item => item._id===sortColumn.path);
    // const orderName = ( findOrder.length>0) ? findOrder[0].name : sortColumn.path;

    // const currentOrder = { name : orderName };

    // const { user } = this.props;

    const { data } = this.getPagedData();

    const calendarOptions = { altInput: true,
      altFormat: "j F Y",
      dateFormat: "Y-m-d"}

    
    return (
      <React.Fragment>
        <div className="row justify-content-end">
          <div className="col-auto mr-auto mb-3">
            <h4>กิจกรรม</h4>
          </div>
          <div className="col-auto mb-3 py-0">
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </div>
          <div className="col-auto mb-3 py-0">
            {this.filterDates &&(<ButtonGroup
              items={this.filterDates}
              selectedItem={this.state.selectFilterDate}
              onItemSelect={this.handleFilterDate}
              valueProperty='value'
            />)}
          </div>
          {selectFilterDate.value==='date' && 
          <div className="col-auto mb-3 py-0">
            <div className="row align-items-center">
              <FontAwesome name="chevron-circle-left" size="2x" onClick={this.handleFilterDateStartPrevious} />
              <div className="d-inline-block mx-2">
              <Flatpickr
                  id='filterDateStart'
                  name='filterDateStart'
                  value={this.state.filterDateStart}
                  onChange={(_,str) => this.handleFilterDateStartChange(str)}
                  options={calendarOptions}
                />
              </div>
              <FontAwesome name="chevron-circle-right" size="2x" onClick={this.handleFilterDateStartNext} />
            </div>
          </div>}
        </div>
        {data && data.length>0 && <LiveCardTable data={data} />}
        {(!data || data.length===0) && 
          <div className="row">
            <div className="col-12">
            <div className="alert alert-secondary text-center" role="alert">
              ไม่มีพบข้อมูล
            </div>
            </div>
          </div>
        }
      </React.Fragment>
      
    );
  }
}

export default History;
