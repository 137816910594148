import React from 'react';
import reviewGood from './../../assets/review/review_good.png';
import reviewNormal from './../../assets/review/review_normal.png';
import reviewBad from './../../assets/review/review_bad.png';

const EventCardReview = ({data}) => {

    return ( <
    React.Fragment>
        {data && 
        <div className="card-text mt-4">
            <span><h5>{data.all}</h5></span>
            <span><h5>{data.canReview}</h5></span>
            <span><h5>{data.doReview}</h5></span>
            <div className="row">
                <div className="col-auto">
                    <div className="card mb-3" style={{width:'200px'}}>
                        <div className="row no-gutters align-items-center">
                            <div className="col-4 p-2">
                                <img src={reviewGood} style={{width: "50px"}} className="card-img ml-1" alt="..." />
                            </div>
                            <div className="col-8">
                                <div className="card-body">
                                    <h4 className="card-title d-inline">พอใจ <span className="badge badge-success">{data.good}</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="card mb-3" style={{width:'200px'}}>
                        <div className="row no-gutters align-items-center">
                            <div className="col-4 p-2">
                                <img src={reviewNormal} style={{width: "50px"}} className="card-img ml-1" alt="..." />
                            </div>
                            <div className="col-8">
                                <div className="card-body">
                                    <h4 className="card-title d-inline">เฉย ๆ <span className="badge badge-info">{data.normal}</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="card mb-3" style={{width:'240px'}}>
                        <div className="row no-gutters align-items-center">
                            <div className="col-4 p-2">
                                <img src={reviewBad} style={{width: "50px"}} className="card-img ml-1" alt="..." />
                            </div>
                            <div className="col-8">
                                <div className="card-body">
                                    <h4 className="card-title d-inline">ไม่พอใจ <span className="badge badge-danger">{data.bad}</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
    </React.Fragment> );
}
 
export default EventCardReview;
