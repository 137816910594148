import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getBranches } from './../../services/branchService';

import _ from "lodash";

import ListGroup from "../common/listGroup";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";

// import { Link } from "react-router-dom";
import SearchBox from "../common/searchBox";
import { getPlans } from "../../services/planService";
import { toast } from "react-toastify";
import PlanTable from './planTable';

import socketIOClient from './../../services/socketioService';

class Plans extends Component {
  _isMounted = false;

  state = {
    data: [],
    types: [],
    branches: [],
    planMode: 'one',
    selectBranch: {id: 'all', name: "All"},
    pageSize: 10,
    currentPage: 1,
    searchQuery: "",
    selectedType: {_id: "all", name: "All Type"},
    sortColumn: { path: "start", order: "asc" }
  };

  listType = [{_id: "all", name: "All Type"},{_id:"walk", name: "Walk"},{_id: "station", name: "Station"} ];
  listOrder = [{_id: "start", name: "Order by Date"},{_id:"type", name: "Order by Type"},{_id: "name", name: "Order by Work Name"} ];
  filterPageSize = [{_id: 5, name: ""}];

  async componentDidMount() {
    this._isMounted = true;

    await this.loadPlans();
    await this.loadBranches();

    this.setupNotify();

  }

  async loadPlans(planMode=this.state.planMode) {
    const toastId = toast.info("Loading...", {
      position: toast.POSITION.BOTTOM_LEFT, autoClose: false, hideProgressBar: true
    });

    const { data: result } = await getPlans(planMode);
    // console.log(result);
    let templateList = [];
    if ( result.status === 'success') {
      templateList = result.data.plans;
      if ( this._isMounted) this.setState({ data: templateList, planMode: planMode });
    }

    toast.update(toastId, {autoClose:1000});
    
  }

  async loadBranches() {
    const { data: result2 } = await getBranches()

    if ( result2.status==='success') {
      const branches = ( result2.data.branches) ? [...[{name: "All", value: "all"}],...result2.data.branches] : null;
      const selectBranch = (branches.length>0) ? branches[0] : null;
  
      if( this._isMounted) {
        this.setState( { branches: branches, selectBranch: selectBranch});
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    socketIOClient.off('update-plan-list');
  }

  setupNotify = () => {
    socketIOClient.on('update-plan-list', async () => {
      // console.log("Notify Update Plans");
      await this.loadPlans();
    })

  }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };



  getPagedData = () => {
    const { pageSize, currentPage, data: alldata } = this.state;
    const { selectBranch, sortColumn, searchQuery } = this.state;

    const filtered =
      selectBranch && selectBranch.name && selectBranch.value!=='all'
        ? alldata.filter(item => item.branch === selectBranch.name)
        : alldata;

    const searched = filtered.filter(item => {
      if (item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())) return true;
      if (item.type && item.type.includes(searchQuery.toLowerCase())) return true;
      if (item.owner_text && item.owner_text.toLowerCase().includes(searchQuery.toLowerCase())) return true;
      if (item.branch && item.branch.toLowerCase().includes(searchQuery.toLowerCase())) return true;
      return false;
    });

    const sorted = _.orderBy(searched, [sortColumn.path], [sortColumn.order]);

    const data = paginate(sorted, currentPage, pageSize);

    return { totalCount: sorted.length, data: data };
  };

  handleChangePlanMode = async (newMode) => {
    if ( newMode===this.state.planMode) return;

    this.loadPlans(newMode);

    if ( this._isMounted) this.setState({planMode: newMode, searchQuery: "", currentPage: 1})
  }

  handleSearch = query => {
    this.setState({ selectedType: null, searchQuery: query, currentPage: 1 });
  };

  handleBranchSelect = branch => {
    this.setState({ selectBranch: branch, searchQuery: "", currentPage: 1 });
  };

  dropdownOrder = select => {
    console.log(select);

    this.setState({ currentPage: 1, sortColumn: { path: select._id, order: (select._id==='start') ? "desc" : "asc"}});

  }

  dropdownType = select => {
    // console.log(select);
    this.setState({ selectedType: select, searchQuery: "", currentPage: 1})
  }

  render() {
    // const { length: count } = this.state.movies;
    const { pageSize, currentPage } = this.state;
    const { sortColumn, searchQuery, planMode='one' } = this.state;

    const buttonDateOneClassName = (planMode === 'one') ? 'btn btn-primary' : 'btn btn-outline-dark';
    const buttonDateDailyClassName = (planMode === 'daily') ? 'btn btn-primary' : 'btn btn-outline-dark';


    const { user } = this.props;

    const { totalCount, data } = this.getPagedData();

    return (
      <React.Fragment>
        <div className="row mb-3">
          <div className="col-auto mr-auto">
          <h4>แผนงาน</h4>
          </div>
          <div className="col-auto">
            {user && (<Link to="/plan/new" className="btn btn-success ml-4">สร้างแผนงาน</Link>)}
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 align-self-center mb-3">
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </div>
          <div className="col-md-8 align-self-center mb-3">
            <div className="row justify-content-end pr-3">
              {<div className="btn-group" role="group" >
                  <button type="button" className={buttonDateOneClassName} onClick={() => this.handleChangePlanMode('one')} >งานครั้งเดียว</button>
                  <button type="button" className={buttonDateDailyClassName} onClick={() => this.handleChangePlanMode('daily')} >งานประจำ</button>
                </div>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col aligh-self-end mb-3">
            <div className="row justify-content-end pr-3">
              {this.state.branches &&(<ListGroup
                items={this.state.branches}
                selectedItem={this.state.selectBranch}
                onItemSelect={this.handleBranchSelect}
                valueProperty='value'
              />)}
            </div>
            {/* <div className="row justify-content-end pr-3 d-none">
              <Dropdown list={this.listOrder} current={currentOrder} onSelect={this.dropdownOrder} />
              <Dropdown list={this.listType} current={selectedType} onSelect={this.dropdownType} />
            </div> */}
          </div>
        </div>
      <div className="row">
        <div className="col">
          <PlanTable
            data={data}
            sortColumn={sortColumn}
            onSort={this.handleSort}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            onPageChange={this.handlePageChange}
            currentPage={currentPage}
          />
        </div>
      </div>
      </React.Fragment>
      
    );
  }
}

export default Plans;
