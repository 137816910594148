import React from 'react';

const ReportOverview = ({data}) => {

    if(!data) return (<div></div>)

    return ( <React.Fragment>
        <div className="row">
            <div className="col-12 col-md-6 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        กิจกรรมทั้งหมด
                    </div>
                    <div className="card-body">
                        <h2 className="card-title">{data.total}</h2>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                    ผู้เข้าร่วมทั้งหมด
                    </div>
                    <div className="card-body">
                        <h2 className="card-title">{data.totalAudience}</h2>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment> );
}
 
export default ReportOverview;